import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableHead, TableBody, TableRow, TableCell, Divider } from '@mui/material';

const IndividualBorrowerBSASchedules = observer(({ borrowers }) => {
    const splitCamelCaseToCapitalCase = (field) => {
        return field
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capital letters
            .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
    };

    const renderSchedule = (schedule, title) => {
        if (!schedule || schedule.length === 0) return null;

        return (
            <Box mb={3}>
                <Typography variant="h6">{title}</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {Object.keys(schedule[0]).map((key) => (
                                <TableCell key={key}>{splitCamelCaseToCapitalCase(key)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {schedule.map((item, index) => (
                            <TableRow key={index}>
                                {Object.values(item).map((value, i) => (
                                    <TableCell key={i}>{value}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const renderBorrowerSchedules = (borrower) => {
        const schedules = [
            { data: borrower.bsas?.a, title: 'Schedule A - Real Estate and Debt' },
            { data: borrower.bsas?.b, title: 'Schedule B - Chattel Debt & Notes Payable' },
            { data: borrower.bsas?.c, title: 'Schedule C - Current Debt' },
            { data: borrower.bsas?.d, title: 'Schedule D - Accounts Receivable' },
            { data: borrower.bsas?.e, title: 'Schedule E - Notes Receivable' },
            { data: borrower.bsas?.f, title: 'Schedule F - Growing Crops' },
            { data: borrower.bsas?.g, title: 'Schedule G - Harvested Crops' },
            { data: borrower.bsas?.h, title: 'Schedule H - Livestock' },
            { data: borrower.bsas?.i, title: 'Schedule I - Market Livestock' },
            { data: borrower.bsas?.j, title: 'Schedule J - Leased Machinery, Equipment and Vehicles' },
            { data: borrower.bsas?.k, title: 'Schedule K - Owned Machinery, Equipment and Vehicles' },
        ];

        return (
            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    {borrower.type === 'Entity' ? borrower.entityName : `${borrower.firstName} ${borrower.lastName}`}
                    {borrower.is_principal ? ' (Principal Borrower)' : ''}
                </Typography>
                {schedules.map((schedule, index) => (
                    <React.Fragment key={index}>
                        {renderSchedule(schedule.data, schedule.title)}
                        {index < schedules.length - 1 && <Divider sx={{ my: 2 }} />}
                    </React.Fragment>
                ))}
            </Box>
        );
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>Individual Borrower BSA Schedules</Typography>
            {borrowers.slice().sort((a, b) => b.is_principal - a.is_principal).map((borrower, index) => (
                <React.Fragment key={borrower.id}>
                    {renderBorrowerSchedules(borrower)}
                    {index < borrowers.length - 1 && <Divider sx={{ my: 4 }} />}
                </React.Fragment>
            ))}
        </Box>
    );
});

export default IndividualBorrowerBSASchedules;