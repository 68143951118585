import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import loanDetailStore from '../store';

const IndividualBorrowerBalanceSheets = observer(() => {
    const { borrowers } = loanDetailStore;

    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
    };

    const renderAssetLiabilitySection = (assets, liabilities, otherAssets, otherLiabilities, sectionTitle) => {
        const totalAssets = Object.values(assets).reduce((sum, value) => sum + (parseFloat(value) || 0), 0);
        const totalLiabilities = Object.values(liabilities).reduce((sum, value) => sum + (parseFloat(value) || 0), 0);

        return (
            <>
                <TableRow>
                    <TableCell colSpan={4}><strong>{sectionTitle}</strong></TableCell>
                </TableRow>
                {Object.entries(assets).map(([key, value]) => (
                    <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell align="right">{formatCurrency(value)}</TableCell>
                        <TableCell>{key in liabilities ? key : ''}</TableCell>
                        <TableCell align="right">{key in liabilities ? formatCurrency(liabilities[key]) : ''}</TableCell>
                    </TableRow>
                ))}
                {otherAssets && otherAssets.map((asset, index) => (
                    <TableRow key={`other-asset-${index}`}>
                        <TableCell>{asset.description}</TableCell>
                        <TableCell align="right">{formatCurrency(asset.amount)}</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                ))}
                {otherLiabilities && otherLiabilities.map((liability, index) => (
                    <TableRow key={`other-liability-${index}`}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{liability.description}</TableCell>
                        <TableCell align="right">{formatCurrency(liability.amount)}</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell><strong>Total {sectionTitle}</strong></TableCell>
                    <TableCell align="right"><strong>{formatCurrency(totalAssets)}</strong></TableCell>
                    <TableCell><strong>Total {sectionTitle}</strong></TableCell>
                    <TableCell align="right"><strong>{formatCurrency(totalLiabilities)}</strong></TableCell>
                </TableRow>
            </>
        );
    };

    const renderBalanceSheet = (borrower) => {
        const balanceSheet = borrower.balanceSheet || {};
        const currentAssets = balanceSheet.currentAssets || {};
        const intermediateAssets = balanceSheet.intermediateAssets || {};
        const longTermAssets = balanceSheet.longTermAssets || {};
        const currentLiabilities = balanceSheet.currentLiabilities || {};
        const intermediateLiabilities = balanceSheet.intermediateLiabilities || {};
        const longTermLiabilities = balanceSheet.longTermLiabilities || {};

        const otherCurrentAssets = balanceSheet.otherCurrentAssets || [];
        const otherIntermediateAssets = balanceSheet.otherNonCurrentAssets || [];
        const otherLongTermAssets = balanceSheet.otherNonRealEstateLongTermAssets || [];
        const otherCurrentLiabilities = balanceSheet.accountsPayable || [];
        const otherIntermediateLiabilities = [];
        const otherLongTermLiabilities = [];

        const totalAssets = Object.values(currentAssets).reduce((sum, value) => sum + (parseFloat(value) || 0), 0) +
            Object.values(intermediateAssets).reduce((sum, value) => sum + (parseFloat(value) || 0), 0) +
            Object.values(longTermAssets).reduce((sum, value) => sum + (parseFloat(value) || 0), 0) +
            otherCurrentAssets.reduce((sum, asset) => sum + (parseFloat(asset.amount) || 0), 0) +
            otherIntermediateAssets.reduce((sum, asset) => sum + (parseFloat(asset.amount) || 0), 0) +
            otherLongTermAssets.reduce((sum, asset) => sum + (parseFloat(asset.amount) || 0), 0);

        const totalLiabilities = Object.values(currentLiabilities).reduce((sum, value) => sum + (parseFloat(value) || 0), 0) +
            Object.values(intermediateLiabilities).reduce((sum, value) => sum + (parseFloat(value) || 0), 0) +
            Object.values(longTermLiabilities).reduce((sum, value) => sum + (parseFloat(value) || 0), 0) +
            otherCurrentLiabilities.reduce((sum, liability) => sum + (parseFloat(liability.amount) || 0), 0);

        const netWorth = totalAssets - totalLiabilities;

        return (
            <Box key={borrower.id} mb={4}>
                <Typography variant="h6" gutterBottom>
                    {borrower.type === 'Entity' ? borrower.entityName : `${borrower.firstName} ${borrower.lastName}`}
                    {borrower.is_principal && " (Principal Borrower)"}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>Balance Sheet Date: {balanceSheet.date || 'N/A'}</Typography>

                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Assets</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell>Liabilities</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderAssetLiabilitySection(currentAssets, currentLiabilities, otherCurrentAssets, otherCurrentLiabilities, "Current Assets/Liabilities")}
                        {renderAssetLiabilitySection(intermediateAssets, intermediateLiabilities, otherIntermediateAssets, otherIntermediateLiabilities, "Intermediate Assets/Liabilities")}
                        {renderAssetLiabilitySection(longTermAssets, longTermLiabilities, otherLongTermAssets, otherLongTermLiabilities, "Long Term Assets/Liabilities")}
                        <TableRow>
                            <TableCell><strong>Total Assets</strong></TableCell>
                            <TableCell align="right"><strong>{formatCurrency(totalAssets)}</strong></TableCell>
                            <TableCell><strong>Total Liabilities</strong></TableCell>
                            <TableCell align="right"><strong>{formatCurrency(totalLiabilities)}</strong></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}></TableCell>
                            <TableCell><strong>Net Worth</strong></TableCell>
                            <TableCell align="right"><strong>{formatCurrency(netWorth)}</strong></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        );
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>Individual Borrower Balance Sheets</Typography>
            {borrowers.slice()
                .sort((a, b) => (b.is_principal ? 1 : -1))
                .map(renderBalanceSheet)}
        </Box>
    );
});

export default IndividualBorrowerBalanceSheets;