// CommonLayout.js
import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import { LeftNavigation, DrawerHeader } from '../GlobalComponents/LeftNavigation';
import TopBar from '../GlobalComponents/TopBar';
import { useStore } from '../GlobalComponents/store';
import {observer} from "mobx-react";

const CommonLayout = observer(({ children }) => {
  const { uiStore } = useStore();

  const handleDrawerOpen = () => {
    uiStore.setOpen(true);
  };

  const handleDrawerClose = () => {
    uiStore.setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopBar open={uiStore.open} handleDrawerOpen={handleDrawerOpen} />
      <LeftNavigation open={uiStore.open} handleDrawerClose={handleDrawerClose} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
});

export default CommonLayout;
