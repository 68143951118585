import React from 'react';
import {observer} from 'mobx-react-lite';
import {Grid, TextField, IconButton, Typography, Box, Button} from '@mui/material';
import {Delete as DeleteIcon} from '@mui/icons-material';
import loanDetailStore from '../store';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const LongTermAssets = observer(({borrowers, consolidated, proforma}) => {
    const {
        updateLoanData,
        loanData
    } = loanDetailStore;

    const calculateArrayFieldSum = (schedule, field) => {
        return borrowers.reduce((sum, borrower) => {
            const array = borrower.bsas?.[schedule] || [];
            const arraySum = array.reduce((innerSum, item) => {
                const value = parseFloat(item?.[field] || 0);
                return innerSum + value;
            }, 0);
            return sum + arraySum;
        }, 0);
    };

    const calculateOtherNonRealEstateLongTermAssetsTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherNonRealEstateLongTermAssets = borrower.balanceSheet?.otherNonRealEstateLongTermAssets || [];
            const otherNonRealEstateLongTermAssetsSum = otherNonRealEstateLongTermAssets.reduce((innerSum, asset) => {
                const value = parseFloat(asset?.amount || 0);
                return innerSum + value;
            }, 0);
            return sum + otherNonRealEstateLongTermAssetsSum;
        }, 0);
    };

    const calculateOtherNonRealEstateLongTermAssetsConsolidatedTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherNonRealEstateLongTermAssets = borrower.balanceSheet?.otherNonRealEstateLongTermAssets || [];
            const otherNonRealEstateLongTermAssetsSum = otherNonRealEstateLongTermAssets.reduce((innerSum, asset) => {
                const value = parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0);
                return innerSum + value;
            }, 0);
            return sum + otherNonRealEstateLongTermAssetsSum;
        }, 0);
    };

    const calculateOtherNonRealEstateLongTermAssetsChangeTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const otherNonRealEstateLongTermAssets = borrower.balanceSheet?.otherNonRealEstateLongTermAssets || [];
            const otherNonRealEstateLongTermAssetsSum = otherNonRealEstateLongTermAssets.reduce((innerSum, asset) => {
                const value = parseFloat(asset.change || 0);
                return innerSum + value;
            }, 0);
            return sum + otherNonRealEstateLongTermAssetsSum;
        }, 0);
    };

    const calculateOtherNonRealEstateLongTermAssetsProformaTotal = () => {
        return calculateOtherNonRealEstateLongTermAssetsConsolidatedTotal() + calculateOtherNonRealEstateLongTermAssetsChangeTotal();
    };

    const calculateConsolidatedTotal = () => {
        return calculateArrayFieldSum('a', 'marketValue') - calculateElims('realEstateOwned') +
            calculateOtherNonRealEstateLongTermAssetsConsolidatedTotal();
    };

    const calculateProformaTotal = () => {
        return calculateProforma('realEstateOwned') +
            calculateOtherNonRealEstateLongTermAssetsProformaTotal();
    };

    const calculateConsolidated = (field) => {
        return calculateArrayFieldSum('a', 'marketValue') - calculateElims(field) +
            (field === 'otherNonRealEstateLongTermAssets' ? calculateOtherNonRealEstateLongTermAssetsConsolidatedTotal() : 0);
    };

    const calculateProforma = (field) => {
        return calculateConsolidated(field) + (loanData.balanceSheetProforma?.longTermAssets?.[`${field}Change`] || 0);
    };

    const calculateElims = (field) => {
        return parseFloat(loanData.consolidatedBalance?.longTermAssets?.[`${field}Elims`] || 0);
    };

    const updateConsolidatedField = (field, value) => {
        updateLoanData({
            consolidatedBalance: {
                ...loanData.consolidatedBalance,
                longTermAssets: {
                    ...loanData.consolidatedBalance?.longTermAssets,
                    [field]: value,
                },
            },
        });
    };

    const updateProformaField = (field, value) => {
        updateLoanData({
            balanceSheetProforma: {
                ...loanData.balanceSheetProforma,
                longTermAssets: {
                    ...loanData.balanceSheetProforma?.longTermAssets,
                    [field]: value,
                },
            },
        });
    };

    const {
        addBalanceSheetField,
        updateBalanceSheetField,
        deleteBalanceSheetField,
    } = loanDetailStore;

    return (
        <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" my={2}>
                Long Term Assets
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
                {consolidated && (
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Real Estate Owned (Schedule A) - Current"
                            value={calculateArrayFieldSum('a', 'marketValue')}
                            InputProps={{readOnly: true}}
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Real Estate Owned (Schedule A) - Elims"
                            value={loanData.consolidatedBalance?.longTermAssets?.realEstateOwnedElims || ""}
                            onChange={(e) =>
                                updateConsolidatedField(
                                    'realEstateOwnedElims',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Real Estate Owned (Schedule A) - Consolidated"
                            value={calculateArrayFieldSum('a', 'marketValue') - calculateElims('realEstateOwned')}
                            InputProps={{readOnly: true}}
                        />
                    </Box>
                )}
                {proforma && (
                    <Box display="flex" gap={2}>
                        <CurrencyTextField
                            variant="outlined"
                            label="Real Estate Owned (Schedule A) - Current"
                            value={calculateArrayFieldSum('a', 'marketValue') - calculateElims('realEstateOwned')}
                            InputProps={{readOnly: true}}
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Real Estate Owned (Schedule A) - Change"
                            value={loanData.balanceSheetProforma?.longTermAssets?.realEstateOwnedChange || ""}
                            onChange={(e) =>
                                updateProformaField(
                                    'realEstateOwnedChange',
                                    parseFloat(e.target.value.replace(/,/g, "")) || ""
                                )
                            }
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Real Estate Owned (Schedule A) - Proforma"
                            value={calculateProforma('realEstateOwned')}
                            InputProps={{readOnly: true}}
                        />
                    </Box>
                )}
                {!consolidated && !proforma && (
                    <CurrencyTextField
                        variant="outlined"
                        label="Real Estate Owned (Schedule A)"
                        value={calculateArrayFieldSum('a', 'marketValue')}
                        InputProps={{readOnly: true}}
                    />
                )}
            </Box>

            <Typography variant="subtitle1" my={2}>Other Non Real Estate Long Term Assets</Typography>
            {borrowers.map((borrower) => (
                <Box key={borrower.id} mb={2}>
                    <Typography variant="subtitle2">{borrower.name}</Typography>
                    {borrower.balanceSheet?.otherNonRealEstateLongTermAssets?.map((asset, index) => (
                        <Box key={index} display="flex" gap={2} mb={2}>
                            <TextField
                                label="Description"
                                value={asset.description}
                                onChange={(e) => updateBalanceSheetField(borrower.id, 'otherNonRealEstateLongTermAssets', index, {
                                    ...asset,
                                    description: e.target.value
                                })}
                                InputProps={{readOnly: consolidated || proforma}}
                            />
                            {consolidated && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={asset.amount}
                                        InputProps={{readOnly: true}}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Elims"
                                        value={asset.elims || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'otherNonRealEstateLongTermAssets', index, {
                                            ...asset,
                                            elims: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Consolidated"
                                        value={(parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                    />
                                </>
                            )}
                            {proforma && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={(parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Change"
                                        value={asset.change || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'otherNonRealEstateLongTermAssets', index, {
                                            ...asset,
                                            change: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Proforma"
                                        value={(parseFloat(asset.amount || 0) - parseFloat(asset.elims || 0) + parseFloat(asset.change || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                    />
                                </>
                            )}
                            {!consolidated && !proforma && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Amount"
                                        value={asset.amount}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'otherNonRealEstateLongTermAssets', index, {
                                            ...asset,
                                            amount: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                    />
                                    <IconButton
                                        onClick={() => deleteBalanceSheetField(borrower.id, 'otherNonRealEstateLongTermAssets', index)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    ))}
                    {!consolidated && !proforma && (
                        <Box display="flex" justifyContent="flex-start" mb={2}>
                            <Button variant="contained"
                                    onClick={() => addBalanceSheetField(borrower.id, 'otherNonRealEstateLongTermAssets')}>
                                Add Row
                            </Button>
                        </Box>
                    )}
                </Box>
            ))}

            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography>Total Long Term Assets:</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {(
                            consolidated ? calculateConsolidatedTotal() :
                                proforma ? calculateProformaTotal() :
                                    calculateArrayFieldSum('a', 'marketValue') + calculateOtherNonRealEstateLongTermAssetsTotal()
                        ).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Typography>
                </Grid>
            </Box>
        </Grid>
    );
});

export default LongTermAssets;