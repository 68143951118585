import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Box, Grid } from '@mui/material';
import {
    calculateTotalCurrentLiabilities,
    calculateTotalIntermediateLiabilities,
    calculateTotalLongTermLiabilities
} from './balanceSheetUtils';
import loanDetailStore from '../store';

const TotalLiabilities = observer(({ borrowers, consolidated, proforma }) => {
    const mode = consolidated ? 'consolidated' : (proforma ? 'proforma' : 'individual');
    const { loanData } = loanDetailStore;

    const totalCurrentLiabilities = calculateTotalCurrentLiabilities(borrowers, mode, loanData);
    const totalIntermediateLiabilities = calculateTotalIntermediateLiabilities(borrowers, mode, loanData);
    const totalLongTermLiabilities = calculateTotalLongTermLiabilities(borrowers, mode, loanData);

    const totalNonCurrentLiabilities = totalIntermediateLiabilities + totalLongTermLiabilities;
    const totalLiabilities = totalCurrentLiabilities + totalIntermediateLiabilities + totalLongTermLiabilities;

    return (
        <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography>Total Current Liabilities:</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {totalCurrentLiabilities.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Typography>
                </Grid>
            </Box>

            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography>Total Non-Current Liabilities:</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {totalNonCurrentLiabilities.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Typography>
                </Grid>
            </Box>

            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography>Total Liabilities:</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {totalLiabilities.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Typography>
                </Grid>
            </Box>
        </Grid>
    );
});

export default TotalLiabilities;