import { makeAutoObservable, runInAction, set } from 'mobx';
import { onSnapshot, collection, addDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, serverTimestamp, arrayUnion } from 'firebase/firestore';
import { debounce } from 'lodash';

class LoanDetailStore {
    loanData = null;
    selectedTab = 0;
    userName = ''; // Add a new observable property for the user's name
    borrowers = [];
    selectedBorrowerId = '';
    subsidiaryEntities = [];
    selectedSubsidiaryCount = 0;

    constructor() {
        makeAutoObservable(this);
    }

    initializeSubsidiaryAnalysis = () => {
        if (!this.loanData.subsidiaryAnalysis) {
            this.updateLoanData({
                subsidiaryAnalysis: {
                    count: 0,
                    initialized: false,
                    entities: [],
                },
            });
        }
    };

    updateSubsidiaryAnalysis = (field, value) => {
        runInAction(() => {
            const path = field.split('.');
            let current = this.loanData.subsidiaryAnalysis;
            for (let i = 0; i < path.length - 1; i++) {
                if (!current[path[i]]) {
                    current[path[i]] = {};
                }
                current = current[path[i]];
            }
            current[path[path.length - 1]] = value;

            if (field === 'count') {
                // Adjust the entities array size when count changes
                const entities = this.loanData.subsidiaryAnalysis.entities || [];
                if (value > entities.length) {
                    for (let i = entities.length; i < value; i++) {
                        entities.push({});
                    }
                } else if (value < entities.length) {
                    entities.length = value;
                }
                this.loanData.subsidiaryAnalysis.entities = entities;
            }
        });

        this.saveSubsidiaryAnalysis();
    };

    saveSubsidiaryAnalysis = debounce(async () => {
        const loanRef = doc(db, 'loans', this.loanData.id);
        try {
            await updateDoc(loanRef, {
                subsidiaryAnalysis: this.loanData.subsidiaryAnalysis,
            });
        } catch (error) {
            console.error('Error saving subsidiary analysis:', error);
        }
    }, 500);

    setSelectedSubsidiaryCount = (count) => {
        this.selectedSubsidiaryCount = count;
        this.subsidiaryEntities = Array(count).fill().map(() => ({ name: '', taxId: '' }));
        this.updateSubsidiaryEntitiesInDb(count);
    };

    updateSubsidiaryEntitiesInDb = debounce(async (count) => {
        const loanRef = doc(db, 'loans', this.loanData.id);
        await updateDoc(loanRef, {
            subsidiaryEntities: this.subsidiaryEntities,
        });
        this.updateLoanLastUpdated();
    }, 500);

    updateSubsidiaryEntity = (index, field, value) => {
        runInAction(() => {
            this.subsidiaryEntities[index][field] = value;
        });
        this.updateSubsidiaryEntityInDb(index, field, value);
    };

    updateSubsidiaryEntityInDb = debounce(async (index, field, value) => {
        const loanRef = doc(db, 'loans', this.loanData.id);
        const subsidiaryEntities = [...this.subsidiaryEntities];
        subsidiaryEntities[index][field] = value;
        await updateDoc(loanRef, { subsidiaryEntities });
        this.updateLoanLastUpdated();
    }, 500);

    addBSAItem = async (borrowerId, schedule) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        await updateDoc(borrowerRef, {
            [`bsas.${schedule}`]: arrayUnion({}),
        });
        this.updateLoanLastUpdated();
    };

    updateBSAData = (borrowerId, schedule, index, field, value) => {
        runInAction(() => {
            const borrowerIndex = this.borrowers.findIndex(borrower => borrower.id === borrowerId);
            if (borrowerIndex !== -1) {
                this.borrowers[borrowerIndex].bsas[schedule][index][field] = value;
            }
        });
        this.updateBSADataInDb(borrowerId, schedule, index, field, value);
    };


    updateBSADataInDb = debounce(async (borrowerId, schedule, index, field, value) => {
        try {
            const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
            const borrowerData = await getDoc(borrowerRef);
            const bsaItems = borrowerData.data().bsas[schedule];
            bsaItems[index][field] = value;
            console.log('bsaItems', bsaItems, schedule, index, field, value);
            await updateDoc(borrowerRef, {
                [`bsas.${schedule}`]: bsaItems,
            });
        } catch (error) {
            console.error('Error updating BSA data:', error);
        }
        this.updateLoanLastUpdated();
    }, 500);

    deleteBSAItem = async (borrowerId, schedule, index) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        const borrowerData = await getDoc(borrowerRef);
        const bsaItems = borrowerData.data().bsas[schedule];
        bsaItems.splice(index, 1);
        await updateDoc(borrowerRef, {
            [`bsas.${schedule}`]: bsaItems,
        });
        this.updateLoanLastUpdated();
    };

    setSelectedBorrowerId = (borrowerId) => {
        this.selectedBorrowerId = borrowerId;
    };

    updateBorrowerBalanceSheet = (borrowerId, field, value) => {
        runInAction(() => {
            const borrowerIndex = this.borrowers.findIndex(borrower => borrower.id === borrowerId);
            if (borrowerIndex !== -1) {
                this.borrowers[borrowerIndex].balanceSheet[field] = value;
            }
        });
        this.updateBorrowerBalanceSheetInDb(borrowerId, field, value);
    };

    updateBorrowerBalanceSheetInDb = debounce(async (borrowerId, field, value) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        await updateDoc(borrowerRef, {
            [`balanceSheet.${field}`]: value,
        });
        this.updateLoanLastUpdated();
    }, 500); // 500ms debounce time

    updateLoanLastUpdated = async () => {
        const loanRef = doc(db, 'loans', this.loanData.id);
        await updateDoc(loanRef, {
            lastUpdated: serverTimestamp(),
        });
    };


    handleBorrowerChange = async (borrowerId, field, value) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        await updateDoc(borrowerRef, { [field]: value });
        this.updateLoanLastUpdated();
    };

    addNewBorrower = async () => {
        const borrowersCollection = collection(db, 'loans', this.loanData.id, 'borrowers');
        await addDoc(borrowersCollection, {
            type: '',
            is_principal: loanDetailStore.borrowers.length === 0, // Set is_principal to true if this is the first borrower
        });
        this.updateLoanLastUpdated();
    };

    removeBorrower = async (borrowerId) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        await deleteDoc(borrowerRef);
        this.updateLoanLastUpdated();
    };

    fetchUserName = async (userId) => {
        try {
            const userDocRef = doc(db, 'Users', userId); // Create a DocumentReference for the user document
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                runInAction(() => {
                    this.userName = userDoc.data().display_name; // Update the user's name
                });
            } else {
                console.log('No such user!');
            }
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    fetchLoanDetails = async (loanId) => {
        try {
            const loanDoc = await getDoc(doc(db, 'loans', loanId));
            if (loanDoc.exists()) {
                runInAction(() => {
                    this.loanData = { id: loanDoc.id, ...loanDoc.data() };
                    this.subsidiaryEntities = this.loanData.subsidiaryEntities || [];
                    this.selectedSubsidiaryCount = this.subsidiaryEntities.length;
                });
            } else {
                console.error('Loan document not found');
            }
        } catch (error) {
            console.error('Error fetching loan details:', error);
        }
    };

    setupLoanDetailsListener = (loanId) => {
        const loanRef = doc(db, 'loans', loanId);
        return onSnapshot(loanRef, (snapshot) => {
            if (snapshot.exists()) {
                runInAction(() => {
                    this.loanData = { id: snapshot.id, ...snapshot.data() };
                });
            } else {
                console.error('Loan document not found');
            }
        }, (error) => {
            console.error('Error fetching loan details:', error);
        });
    };

    updateLoanDataInDbForTextFields = debounce(async (loanId, updatedData) => {
        try {
            const loanRef = doc(db, 'loans', loanId);
            await updateDoc(loanRef, { ...updatedData, lastUpdated: serverTimestamp() });
        } catch (error) {
            console.error('Error updating loan data:', error);
        }
    }, 1000); // 1000ms is the debounce delay for text fields

    // Debounced function for radio/checkbox inputs with a shorter delay
    updateLoanDataInDbForRadioCheckbox = debounce(async (loanId, updatedData) => {
        try {
            const loanRef = doc(db, 'loans', loanId);
            await updateDoc(loanRef, { ...updatedData, lastUpdated: serverTimestamp() });
        } catch (error) {
            console.error('Error updating loan data:', error);
        }
    }, 300);

    updateLoanDataInDb = debounce(async (loanId, updatedData) => {
        try {
            const loanRef = doc(db, 'loans', loanId);
            await updateDoc(loanRef, { ...updatedData, lastUpdated: serverTimestamp() });
        } catch (error) {
            console.error('Error updating loan data:', error);
        }
    }, 500); // 500ms is the debounce delay

    removeUseOfFunds = (index) => {
        runInAction(() => {
            const updatedUseOfFunds = [...this.loanData.application.useOfFunds];
            updatedUseOfFunds.splice(index, 1);
            this.loanData = {
                ...this.loanData,
                application: {
                    ...this.loanData.application,
                    useOfFunds: updatedUseOfFunds,
                },
            };
        });

        this.updateLoanDataInDb(this.loanData.id, this.loanData);
    };

    updateLoanData = (updatedData) => {
        runInAction(() => {
            this.loanData = {
                ...this.loanData,
                ...updatedData,
            };
            this.updateLoanDataInDb(this.loanData.id, updatedData);
        });
    };

    setSelectedTab = (tabIndex) => {
        this.selectedTab = tabIndex;
      };
      
    addUseOfFunds = () => {
        runInAction(() => {
            const newUseOfFunds = [...(this.loanData.application?.useOfFunds || []), {
                loanPurpose: '',
                description: '',
                amount: '',
            }];

            this.updateLoanData({
                application: {
                    ...this.loanData.application,
                    useOfFunds: newUseOfFunds,
                },
            });
        });
    };

    setupBorrowersListener = (loanId) => {
        const borrowersCollection = collection(db, 'loans', loanId, 'borrowers');
        return onSnapshot(borrowersCollection, (snapshot) => {
            runInAction(() => {
                this.borrowers = snapshot.docs.map((doc) => {
                    const borrowerData = doc.data();
                    if (borrowerData.incomeAndExpenses && typeof borrowerData.incomeAndExpenses.data === 'object') {
                        const dataArray = Object.entries(borrowerData.incomeAndExpenses.data)
                            .sort(([a], [b]) => a.localeCompare(b))
                            .map(([_, value]) => value);
                        borrowerData.incomeAndExpenses.data = dataArray;
                    }
                    return { id: doc.id, ...borrowerData };
                });
            });
        }, (error) => {
            console.error('Error fetching borrowers:', error);
        });
    };


    addBalanceSheetField = async (borrowerId, field) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        await updateDoc(borrowerRef, {
            [`balanceSheet.${field}`]: arrayUnion({ description: '', amount: 0 }),
        });
        this.updateLoanLastUpdated();
    };

    updateBalanceSheetField = (borrowerId, field, index, value) => {
        console.log(borrowerId, field, index, value)
        const borrowerIndex = this.borrowers.findIndex(borrower => borrower.id === borrowerId);
        if (borrowerIndex !== -1) {
            runInAction(() => {
                this.borrowers[borrowerIndex].balanceSheet[field][index] = value;
            });
            this.updateBalanceSheetFieldInDb(borrowerId, field, index, value);
        }
    };

    updateBalanceSheetFieldInDb = debounce(async (borrowerId, field, index, value) => {
        if (value !== undefined) {
            const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
            const borrowerDoc = await getDoc(borrowerRef);
            const borrowerObj = borrowerDoc.data();

            // Check if balanceSheet and the field exist, if not initialize them
            if (!borrowerObj.balanceSheet) {
                borrowerObj.balanceSheet = {};
            }
            if (!borrowerObj.balanceSheet[field]) {
                borrowerObj.balanceSheet[field] = [];
            }

            borrowerObj.balanceSheet[field][index] = value;
            try {
                await updateDoc(borrowerRef, {
                    [`balanceSheet.${field}`]: borrowerObj.balanceSheet[field],
                });
                //this.updateLoanLastUpdated();
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    }, 500); // 500ms debounce time

    deleteBalanceSheetField = async (borrowerId, field, index) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        const borrowerIndex = this.borrowers.findIndex(borrower => borrower.id === borrowerId);
        if (borrowerIndex !== -1) {
            const balanceSheetField = this.borrowers[borrowerIndex].balanceSheet?.[field] || [];
            balanceSheetField.splice(index, 1);
            await updateDoc(borrowerRef, {
                [`balanceSheet.${field}`]: balanceSheetField,
            });
            this.updateLoanLastUpdated();
        }
    };

    saveToFirestore = debounce(async (loanId, loanData) => {
        try {
            const loanRef = doc(db, 'loans', loanId);
            await updateDoc(loanRef, { ...loanData, lastUpdated: serverTimestamp() });
            console.log('Loan data saved to Firestore');
        } catch (error) {
            console.error('Error saving loan data to Firestore:', error);
        }
    }, 500);


    updateIncomeAndExpenses = (borrowerId, index, field, value) => {
        const borrowerIndex = this.borrowers.findIndex((borrower) => borrower.id === borrowerId);
        if (borrowerIndex !== -1) {
            runInAction(() => {
                if (!this.borrowers[borrowerIndex].incomeAndExpenses) {
                    set(this.borrowers[borrowerIndex], 'incomeAndExpenses', {});
                }
                if (!Array.isArray(this.borrowers[borrowerIndex].incomeAndExpenses.data)) {
                    set(this.borrowers[borrowerIndex].incomeAndExpenses, 'data', []);
                }
                if (index !== null) {
                    if (!this.borrowers[borrowerIndex].incomeAndExpenses.data[index]) {
                        set(this.borrowers[borrowerIndex].incomeAndExpenses.data, index, {});
                    }
                    set(this.borrowers[borrowerIndex].incomeAndExpenses.data[index], field, value);
                    console.log(`Updating ${field} at index ${index} with value: ${value}`);
                } else {
                    set(this.borrowers[borrowerIndex].incomeAndExpenses, field, value);
                    console.log(`Updating ${field} with value: ${value}`);
                }
            });
            if (index !== null) {
                this.updateIncomeAndExpensesInDb(borrowerId, `data.${index}.${field}`, value);
            } else {
                this.updateIncomeAndExpensesInDb(borrowerId, field, value);
            }
        }
    };

    updateIncomeAndExpensesInDb = debounce(async (borrowerId, field, value) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        const updateData = {};
        updateData[`incomeAndExpenses.${field}`] = value;
        await updateDoc(borrowerRef, updateData);
        this.updateLoanLastUpdated();
    }, 500);

    updateIncomeAndExpensesProforma = (field, value) => {
        runInAction(() => {
            if (!this.loanData.incomeAndExpensesProforma) {
                set(this.loanData, 'incomeAndExpensesProforma', {});
            }
            set(this.loanData.incomeAndExpensesProforma, field, value);
        });
        this.updateIncomeAndExpensesProformaInDb(field, value);
    };

    updateIncomeAndExpensesProformaInDb = debounce(async (field, value) => {
        const loanRef = doc(db, 'loans', this.loanData.id);
        const updateData = {};
        updateData[`incomeAndExpensesProforma.${field}`] = value;
        await updateDoc(loanRef, updateData);
        this.updateLoanLastUpdated();
    }, 500);

    updateBSAProformaData = (borrowerId, schedule, index, field, value) => {
        runInAction(() => {
          const borrower = this.borrowers.find(b => b.id === borrowerId);
          if (borrower) {
            if (!borrower.bsas[schedule][index].proforma) {
              borrower.bsas[schedule][index].proforma = {};
            }
            borrower.bsas[schedule][index].proforma[field] = value;
          }
        });
        this.updateBSAProformaDataInDb(borrowerId, schedule, index, field, value);
      };
    
      updateBSAProformaDataInDb = debounce(async (borrowerId, schedule, index, field, value) => {
        try {
          const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
          const borrowerData = await getDoc(borrowerRef);
          const bsaItems = borrowerData.data().bsas[schedule];
          if (!bsaItems[index].proforma) {
            bsaItems[index].proforma = {};
          }
          bsaItems[index].proforma[field] = value;
          await updateDoc(borrowerRef, {
            [`bsas.${schedule}`]: bsaItems,
          });
        } catch (error) {
          console.error('Error updating BSA proforma data:', error);
        }
        this.updateLoanLastUpdated();
      }, 500);
    
      addBSAProformaItem = async (schedule) => {
        const principalBorrower = this.borrowers.find(b => b.is_principal);
        if (principalBorrower) {
          runInAction(() => {
            if (!principalBorrower.bsas[schedule]) {
              principalBorrower.bsas[schedule] = [];
            }
            principalBorrower.bsas[schedule].push({ proforma: {} });
          });
          await this.addBSAProformaItemInDb(principalBorrower.id, schedule);
        }
      };
    
      addBSAProformaItemInDb = async (borrowerId, schedule) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        await updateDoc(borrowerRef, {
          [`bsas.${schedule}`]: arrayUnion({ proforma: {} }),
        });
        this.updateLoanLastUpdated();
      };
    
      deleteBSAProformaItem = async (borrowerId, schedule, index) => {
        const borrower = this.borrowers.find(b => b.id === borrowerId);
        if (borrower && borrower.bsas[schedule]) {
          runInAction(() => {
            borrower.bsas[schedule].splice(index, 1);
          });
          await this.deleteBSAProformaItemInDb(borrowerId, schedule, index);
        }
      };
    
      deleteBSAProformaItemInDb = async (borrowerId, schedule, index) => {
        const borrowerRef = doc(db, 'loans', this.loanData.id, 'borrowers', borrowerId);
        const borrowerData = await getDoc(borrowerRef);
        const bsaItems = borrowerData.data().bsas[schedule];
        bsaItems.splice(index, 1);
        await updateDoc(borrowerRef, {
          [`bsas.${schedule}`]: bsaItems,
        });
        this.updateLoanLastUpdated();
      };

}


const loanDetailStore = new LoanDetailStore();

export default loanDetailStore;