import React, {useCallback, useMemo} from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const ScheduleK = observer(({ borrowerData, readOnly, proforma }) => {
  const { updateBSAData, addBSAItem, deleteBSAItem, updateBSAProformaData, addBSAProformaItem, deleteBSAProformaItem, selectedBorrowerId } = loanDetailStore;

  const selectedBorrowerLocalId = useMemo(() => {
    let result;
    if (readOnly || proforma) {
      result = borrowerData.find(borrower => borrower.is_principal === true)["id"];
    } else {
      result = selectedBorrowerId;
    }
    if (!result) {
      console.warn('No borrower found!');
    }
    return result;
  }, [selectedBorrowerId, readOnly, proforma, borrowerData]);

  const selectedBorrower = useMemo(() => {
    return borrowerData.find(borrower => borrower.id === selectedBorrowerLocalId);
  }, [selectedBorrowerLocalId, borrowerData]);

  const handleUpdateItem = useCallback((borrowerId, index, field, value) => {
    if (proforma) {
      updateBSAProformaData(borrowerId, 'k', index, field, value);
    } else {
      updateBSAData(borrowerId, 'k', index, field, value);
    }
  }, [updateBSAData, updateBSAProformaData, proforma]);

  const handleAddItem = useCallback(() => {
    if (proforma) {
      addBSAProformaItem('k');
    } else {
      addBSAItem(selectedBorrowerLocalId, 'k');
    }
  }, [addBSAItem, addBSAProformaItem, proforma, selectedBorrowerLocalId]);

  const handleDeleteItem = useCallback((borrowerId, index) => {
    if (proforma) {
      deleteBSAProformaItem(borrowerId, 'k', index);
    } else {
      deleteBSAItem(borrowerId, 'k', index);
    }
  }, [deleteBSAItem, deleteBSAProformaItem, proforma]);

  const getFieldValue = useCallback((item, field) => {
    return proforma ? (item.proforma?.[field] ?? item[field] ?? '') : (item[field] ?? '');
  }, [proforma]);

  const renderScheduleItems = () => {
    if (readOnly) {
      return borrowerData.flatMap(borrower => borrower.bsas?.k || []);
    }

    if (proforma) {
      return borrowerData.flatMap(borrower =>
          (borrower.bsas?.k || []).map(item => ({
            ...item,
            ...(item.proforma || {}),
            borrowerId: borrower.id,
          }))
      );
    }

    return (selectedBorrower.bsas?.k || []).map((item, index) => ({ ...item, index }));
  };

  const scheduleItems = renderScheduleItems();

  const calculateTotals = () => {
    return scheduleItems.reduce((totals, item) => {
      totals.depreciatedValue += parseFloat(getFieldValue(item, 'depreciatedValue')) || 0;
      totals.marketValue += parseFloat(getFieldValue(item, 'marketValue')) || 0;
      return totals;
    }, { depreciatedValue: 0, marketValue: 0 });
  };

  const totals = calculateTotals();

  return (
      <>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Schedule K - Owned Machinery, Equipment and Vehicles (Enter Gross Value, Upload List)</Typography>
        {scheduleItems.map((item, index) => (
            <Box key={index} mb={2}>
              <Box display="flex" gap={2} mb={1}>
                <TextField
                    label="Year"
                    type="number"
                    value={getFieldValue(item, 'year')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'year', parseInt(e.target.value) || '')}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Make"
                    value={getFieldValue(item, 'make')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'make', e.target.value)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Description"
                    value={getFieldValue(item, 'description')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'description', e.target.value)}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    variant="outlined"
                    label="Depreciated Value"
                    value={getFieldValue(item, 'depreciatedValue')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'depreciatedValue', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    variant="outlined"
                    label="Market Value"
                    value={getFieldValue(item, 'marketValue')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'marketValue', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    InputProps={{ readOnly: readOnly }}
                />

              {(!readOnly && !proforma) && (
                  <IconButton onClick={() => handleDeleteItem(item.borrowerId || selectedBorrowerLocalId, index)}>
                    <DeleteIcon />
                  </IconButton>
              )}
              </Box>
            </Box>
        ))}
        {(!readOnly && !proforma) && (
            <Box display="flex" justifyContent="flex-start" mb={2}>
              <Button variant="contained" onClick={handleAddItem}>
                Add Item
              </Button>
            </Box>
        )}
        {(readOnly || proforma) && (
            <Box display="flex" gap={2} my={3} justifyContent="flex-end">
              <CurrencyTextField
                  variant="outlined"
                  label="Total Depreciated Value"
                  value={totals.depreciatedValue}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
              <CurrencyTextField
                  variant="outlined"
                  label="Total Market Value"
                  value={totals.marketValue}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
            </Box>
        )}
      </>
  );
});

export default ScheduleK;