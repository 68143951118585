import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import CommonLayout from '../GlobalComponents/CommonLayout';
import loansStore from '../LoanListView/store';
import { format } from 'date-fns';

const DashboardComponent = observer(() => {
    const [totalLoanAmount, setTotalLoanAmount] = useState(0);
    const [averageLoanAmount, setAverageLoanAmount] = useState(0);
    const [loansByStatus, setLoansByStatus] = useState({});
    const [recentLoans, setRecentLoans] = useState([]);

    useEffect(() => {
        if (loansStore.loans.length === 0) {
            loansStore.fetchLoans();
        } else {
            processLoanData();
        }
        // eslint-disable-next-line
    }, [loansStore.loans]);

    const processLoanData = () => {
        const total = loansStore.loans.reduce((sum, loan) => sum + (Number(loan.loanAmountRequested) || 0), 0);
        const average = total / loansStore.loans.length;

        const statusCount = loansStore.loans.reduce((acc, loan) => {
            acc[loan.status] = (acc[loan.status] || 0) + 1;
            return acc;
        }, {});

        setTotalLoanAmount(total);
        setAverageLoanAmount(average);
        setLoansByStatus(statusCount);
        setRecentLoans(loansStore.loans.slice(0, 5));
    };

    const loanTrendData = loansStore.loans.map(loan => ({
        date: format(loan.created_at.toDate(), 'MM/dd'),
        amount: Number(loan.loanAmountRequested) || 0
    })).sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
        <CommonLayout>
            <Typography variant="h4" mb={3}>Loan Dashboard</Typography>

            {loansStore.loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3}>
                    {/* Summary Cards */}
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>Total Loan Amount</Typography>
                                <Typography variant="h5">${totalLoanAmount.toLocaleString()}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>Average Loan Amount</Typography>
                                <Typography variant="h5">${averageLoanAmount.toLocaleString()}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>Total Loans</Typography>
                                <Typography variant="h5">{loansStore.loans.length}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Loan Trend Chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Loan Amount Trend</Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={loanTrendData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="amount" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>

                    {/* Loans by Status */}
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Loans by Status</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Status</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(loansByStatus).map(([status, count]) => (
                                            <TableRow key={status}>
                                                <TableCell component="th" scope="row">{status}</TableCell>
                                                <TableCell align="right">{count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>

                    {/* Recent Loans */}
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Recent Loans</Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Borrower</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            <TableCell align="right">Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recentLoans.map((loan) => (
                                            <TableRow key={loan.id}>
                                                <TableCell component="th" scope="row">{loan.principalBorrower}</TableCell>
                                                <TableCell align="right">${Number(loan.loanAmountRequested).toLocaleString()}</TableCell>
                                                <TableCell align="right">{format(loan.created_at.toDate(), 'MM/dd/yyyy')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </CommonLayout>
    );
});

export default DashboardComponent;