import React from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Typography, Grid, TextField} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';
import IncomeStatementTrendAnalysis from "./IncomeStatementTrendAnalysis";

const PeerDataBalanceSheetTrendAnalysis = observer(() => {
    const {loanData, updateLoanData} = loanDetailStore;

    const handleInputChange = (field, value) => {
        updateLoanData({
            loanSummary: {
                ...loanData.loanSummary,
                balanceSheetTrend: {
                    ...loanData.loanSummary.balanceSheetTrend,
                    [field]: value,
                },
            },
        });
    };
    const calculateTotalAssets = (year) => {
        const trend = loanData.loanSummary?.balanceSheetTrend?.[year] || {};
        return (parseFloat(trend.currentAssets) || 0) +
            (parseFloat(trend.otherNonCurrentAssets) || 0) +
            (parseFloat(trend.realEstate) || 0);
    };

    const calculateTotalLiabilities = (year) => {
        const trend = loanData.loanSummary?.balanceSheetTrend?.[year] || {};
        return (parseFloat(trend.currentLiabilities) || 0) +
            (parseFloat(trend.otherNonCurrentLiabilities) || 0) +
            (parseFloat(trend.realEstateLiabilities) || 0);
    };

    const calculateNetWorth = (year) => {
        return calculateTotalAssets(year) - calculateTotalLiabilities(year);
    };

    const years = ['year1', 'year2', 'year3', 'year4', 'current', 'proForma'];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
                <Typography variant="h6" gutterBottom>Balance Sheet Trend Analysis</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography>Year:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            {year === 'current' ? (
                                <TextField
                                    value="4/1/2024"
                                    InputProps={{readOnly: true}}
                                />
                            ) : year === 'proForma' ? (
                                <TextField
                                    value="Pro-Forma"
                                    InputProps={{readOnly: true}}
                                />
                            ) : (
                                <DatePicker
                                    views={['year']}
                                    value={loanData.loanSummary?.balanceSheetTrend?.[year]?.date || null}
                                    onChange={(newValue) => handleInputChange(`${year}.date`, newValue)}
                                />
                            )}
                        </Grid>
                    ))}

                    {/* Current Assets */}
                    <Grid item xs={2}>
                        <Typography>Current Assets:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={loanData.loanSummary?.balanceSheetTrend?.[year]?.currentAssets || ''}
                                onChange={(e, value) => handleInputChange(`${year}.currentAssets`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Other N/Curr Ass. */}
                    <Grid item xs={2}>
                        <Typography>Other N/Curr Ass.:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={loanData.loanSummary?.balanceSheetTrend?.[year]?.otherNonCurrentAssets || ''}
                                onChange={(e, value) => handleInputChange(`${year}.otherNonCurrentAssets`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Real Estate */}
                    <Grid item xs={2}>
                        <Typography>Real Estate:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={loanData.loanSummary?.balanceSheetTrend?.[year]?.realEstate || ''}
                                onChange={(e, value) => handleInputChange(`${year}.realEstate`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Total Assets */}
                    <Grid item xs={2}>
                        <Typography>Total Assets:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={calculateTotalAssets(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Current Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Current Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={loanData.loanSummary?.balanceSheetTrend?.[year]?.currentLiabilities || ''}
                                onChange={(e, value) => handleInputChange(`${year}.currentLiabilities`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Other N/Curr Liab. */}
                    <Grid item xs={2}>
                        <Typography>Other N/Curr Liab.:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={loanData.loanSummary?.balanceSheetTrend?.[year]?.otherNonCurrentLiabilities || ''}
                                onChange={(e, value) => handleInputChange(`${year}.otherNonCurrentLiabilities`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Real Estate Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Real Estate Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={loanData.loanSummary?.balanceSheetTrend?.[year]?.realEstateLiabilities || ''}
                                onChange={(e, value) => handleInputChange(`${year}.realEstateLiabilities`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Total Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Total Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={calculateTotalLiabilities(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Net Worth */}
                    <Grid item xs={2}>
                        <Typography>Net Worth:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={calculateNetWorth(year)}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Change In Net Worth */}
                    <Grid item xs={2}>
                        <Typography>Change In Net Worth:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={index > 0 ? calculateNetWorth(year) - calculateNetWorth(years[index - 1]) : ''}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* RE Appreciation */}
                    <Grid item xs={2}>
                        <Typography>RE Appreciation:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={loanData.loanSummary?.balanceSheetTrend?.[year]?.reAppreciation || ''}
                                onChange={(e, value) => handleInputChange(`${year}.reAppreciation`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Earned Gain */}
                    <Grid item xs={2}>
                        <Typography>Earned Gain:</Typography>
                    </Grid>
                    {years.map((year, index) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={index > 0 ? (calculateNetWorth(year) - calculateNetWorth(years[index - 1])) - (parseFloat(loanData.loanSummary?.balanceSheetTrend?.[year]?.reAppreciation) || 0) : ''}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Current Ratio */}
                    <Grid item xs={2}>
                        <Typography>Current Ratio:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <TextField
                                value={(parseFloat(loanData.loanSummary?.balanceSheetTrend?.[year]?.currentAssets) / parseFloat(loanData.loanSummary?.balanceSheetTrend?.[year]?.currentLiabilities)).toFixed(2) || ''}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Debt/Asset Ratio */}
                    <Grid item xs={2}>
                        <Typography>Debt/Asset Ratio:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <TextField
                                value={(calculateTotalLiabilities(year) / calculateTotalAssets(year)).toFixed(2) || ''}
                                InputProps={{readOnly: true}}
                            />
                        </Grid>
                    ))}

                    {/* Contingent Liabilities */}
                    <Grid item xs={2}>
                        <Typography>Contingent Liabilities:</Typography>
                    </Grid>
                    {years.map((year) => (
                        <Grid item xs={1.5} key={year}>
                            <CurrencyTextField
                                value={loanData.loanSummary?.balanceSheetTrend?.[year]?.contingentLiabilities || ''}
                                onChange={(e, value) => handleInputChange(`${year}.contingentLiabilities`, value)}
                                InputProps={{readOnly: year === 'current' || year === 'proForma'}}
                            />
                        </Grid>
                    ))}

                    {/* Comments */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            label="Comments"
                            value={loanData.loanSummary?.balanceSheetTrend?.comments || ''}
                            onChange={(e) => handleInputChange('comments', e.target.value)}
                        />
                    </Grid>

                </Grid>
                <IncomeStatementTrendAnalysis/>
            </Box>
        </LocalizationProvider>
    );
});

export default PeerDataBalanceSheetTrendAnalysis;