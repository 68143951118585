import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField } from '@mui/material';
import loanDetailStore from '../store';
import { Checkbox, FormControlLabel } from '@mui/material';

const FinalDetails = observer(() => {
  const { loanData, updateLoanData } = loanDetailStore;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    updateLoanData({ application: { ...loanData.application, finalDetails: { ...loanData.application.finalDetails, [name]: value } } });
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    updateLoanData({ application: { ...loanData.application, finalDetails: { ...loanData.application.finalDetails, [name]: checked } } });
  };

  return (
    <Box>
      <Typography variant="h6" mb={1}>Final Details</Typography>
      <TextField
        label="Describe any labor performed, materials furnished, or contracts made in connection with the erection or alteration or other improvements on the offered security within the past six months"
        name="laborPerformed"
        value={loanData?.application?.finalDetails?.laborPerformed || ''}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        label="List all leases on the security, including lessees, amounts, property leased and the lease expiration dates"
        name="leasesOnSecurity"
        value={loanData?.application?.finalDetails?.leasesOnSecurity || ''}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        label="Comments"
        name="comments"
        value={loanData?.application?.finalDetails?.comments || ''}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="agriculturalProduction"
            checked={loanData?.application?.finalDetails?.agriculturalProduction || false}
            onChange={handleCheckboxChange}
          />
        }
        label="Each of the undersigned applicants certify that they are or will be actively engaged in agricultural production, either in their own right or as a landlord, and they intend to continue agricultural production on the real estate used as a collateral for the loan."
      />
      <FormControlLabel
        control={
          <Checkbox
            name="revolvingCredit"
            checked={loanData?.application?.finalDetails?.revolvingCredit || false}
            onChange={handleCheckboxChange}
          />
        }
        label="If the loan product selected involves a revolving line of credit, the Applicant represents and agrees that any and all requests for funds under the loan will be used only for agricultural or other business purposes."
      />
    </Box>
  );
});

export default FinalDetails;