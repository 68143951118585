import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    Box,
    Typography,
    TextField,
    Grid,
    Checkbox,
    FormControlLabel,
    Paper,
    CircularProgress
} from '@mui/material';
import loanDetailStore from '../store';
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../../firebase';
import RealEstateSecurity from "./RealEstateSecurity";


const LoanSummary = observer(() => {
    const {loanData, borrowers, updateLoanData} = loanDetailStore;
    const [originatorData, setOriginatorData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOriginatorData = async () => {
            if (loanData?.lending_organization) {
                const organizationRef = doc(db, 'organizations', loanData.lending_organization.id);
                const organizationDoc = await getDoc(organizationRef);
                if (organizationDoc.exists()) {
                    setOriginatorData(organizationDoc.data());
                }
            }
            setLoading(false);
        };

        fetchOriginatorData();
    }, [loanData?.lending_organization]);

    const principalBorrower = borrowers.find(b => b.is_principal) || {};

    const handleCheckboxChange = (event) => {
        updateLoanData({
            loanSummary: {
                ...loanData.loanSummary,
                hasCrossCollateralizedLoan: event.target.checked,
            },
        });
    };

    const handleCommentsChange = (event) => {
        updateLoanData({
            loanSummary: {
                ...loanData.loanSummary,
                comments: event.target.value,
            },
        });
    };
    const maskSSN = (ssn) => {
        if (!ssn) return '';
        const lastFour = ssn.slice(-4);
        return `XXX-XX-${lastFour}`;
    };

    if (loading) {
        return <CircularProgress/>;
    }

    return (
        <Box>
            <Typography variant="h6" gutterBottom>Seller, Borrower and Loan Summary Information</Typography>

            {/* Seller Information */}
            <Paper elevation={3} sx={{p: 2, mb: 2}}>
                <Typography variant="subtitle1" gutterBottom>Seller Information:</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Seller ID"
                            value={originatorData?.id || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={originatorData?.name || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Address"
                            value={originatorData?.street_address || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="City"
                            value={originatorData?.city || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="State"
                            value={originatorData?.state || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Zip"
                            value={originatorData?.zip || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Phone"
                            value={originatorData?.phone_number || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            value={originatorData?.email || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* Borrower Information */}
            <Paper elevation={3} sx={{p: 2, mb: 2}}>
                <Typography variant="subtitle1" gutterBottom>Borrower Information:</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={principalBorrower.type === 'Entity' ? principalBorrower.entityName : `${principalBorrower.firstName} ${principalBorrower.lastName}`}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Borrower Type"
                            value={principalBorrower.type || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={principalBorrower.type === 'Individual' ? 'SSN' : 'TIN'}
                            value={principalBorrower.type === 'Individual' ? maskSSN(principalBorrower.taxId) : principalBorrower.taxId}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Billing Address"
                            value={principalBorrower.address || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="City"
                            value={principalBorrower.city || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="State"
                            value={principalBorrower.state || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Zip"
                            value={principalBorrower.zip || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Business Phone"
                            value={principalBorrower.phone || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Home Phone"
                            value={principalBorrower.homePhone || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Fax Number"
                            value={principalBorrower.fax || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Citizens or Nationals?"
                            value={principalBorrower.nonUSCitizen ? 'No' : 'Yes'}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* Related Loans */}
            <Paper elevation={3} sx={{p: 2, mb: 2, bgcolor: 'grey.200'}}>
                <Typography variant="subtitle1" gutterBottom>Related Loans</Typography>
                <Typography>Information not available</Typography>
            </Paper>

            {/* Cross-Collateralized And Cross-Defaulted Loan */}
            <Paper elevation={3} sx={{p: 2, mb: 2}}>
                <Typography variant="subtitle1" gutterBottom>Cross-Collateralized And Cross-Defaulted Loan</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={loanData?.loanSummary?.hasCrossCollateralizedLoan || false}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="Has Cross Loan?"
                />
            </Paper>

            {/* Loan Requested */}
            <Paper elevation={3} sx={{p: 2, mb: 2}}>
                <Typography variant="subtitle1" gutterBottom>Loan Requested:</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Principal"
                            value={loanData?.application?.loanTerms?.loanAmountRequested || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Billing Frequency"
                            value={loanData?.application?.loanTerms?.loan_payment_frequency || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Description"
                            value={loanData?.application?.loanTerms?.loan_product || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Max. Rate Req (%)"
                            value={loanData?.application?.loanTerms?.maxInterestRate || ''}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Term"
                            value={`${loanData?.application?.loanTerms?.loan_amortization_years || ''} Years`}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Amortization"
                            value={`${loanData?.application?.loanTerms?.loan_amortization_years || ''} Years`}
                            InputProps={{readOnly: true}}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* Use of Funds Table */}
            <Paper elevation={3} sx={{p: 2, mb: 2}}>
                <Typography variant="subtitle1" gutterBottom>Use of Funds:</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}><Typography fontWeight="bold">Loan Purpose</Typography></Grid>
                    <Grid item xs={4}><Typography fontWeight="bold">Description</Typography></Grid>
                    <Grid item xs={2}><Typography fontWeight="bold">Amount($)</Typography></Grid>
                    <Grid item xs={2}><Typography fontWeight="bold">(%)</Typography></Grid>
                </Grid>
                {loanData?.application?.useOfFunds?.map((item, index) => (
                    <Grid container spacing={2} key={index}>
                        <Grid item xs={4}><Typography>{item.loanPurpose}</Typography></Grid>
                        <Grid item xs={4}><Typography>{item.description}</Typography></Grid>
                        <Grid item xs={2}><Typography>{item.amount}</Typography></Grid>
                        <Grid item
                              xs={2}><Typography>{((item.amount / loanData?.application?.loanTerms?.loanAmountRequested) * 100).toFixed(2)}%</Typography></Grid>
                    </Grid>
                ))}
            </Paper>

            {/* Comments */}
            <Paper elevation={3} sx={{p: 2, mb: 2}}>
                <Typography variant="subtitle1" gutterBottom>Comments:</Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={loanData?.loanSummary?.comments || ''}
                    onChange={handleCommentsChange}
                />
            </Paper>
            <RealEstateSecurity loanData={loanData} updateLoanData={updateLoanData} />
        </Box>
    );
});

export default LoanSummary;