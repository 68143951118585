import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import loanDetailStore from './store';
import Application from './application/view';
import BalanceSheet from './balanceSheet/BalanceSheet';
import BSA from './bsa/BSA';
import Typography from '@mui/material/Typography';
import { formatDistanceToNow } from 'date-fns';
import ConsolidatedBalanceSheet from './balanceSheet/ConsolidatedBalanceSheet';
import CommonLayout from '../GlobalComponents/CommonLayout';
import uiStore from '../GlobalComponents/store';
import IncomeAndExpenses from './incomeAndExpenses/IncomeAndExpenses';
import IncomeAndExpensesProforma from './incomeAndExpenses/IncomeAndExpensesProforma';
import SubsidiaryAnalysis from './subsidiaryAnalysis/SubsidiaryAnalysis';
import LoanSummary from "./loanSummary/LoanSummary";
import BalanceSheetProforma from "./balanceSheet/BalanceSheetProforma";

const tabNames = [
  'application',
  'balance-sheet',
  'bsa',
  'consolidated-balance-sheet',
  'consolidated-bsa',
  'consolidated-bs-proforma',
  'consolidated-bsa-proforma',
  'income-and-expenses',
  'income-and-expenses-proforma',
  'subsidiary-analysis',
  'loan-summary'
];

const LoanDetailView = observer(() => {
  const { id, tab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribeLoanDetails = loanDetailStore.setupLoanDetailsListener(id);
    const unsubscribeBorrowers = loanDetailStore.setupBorrowersListener(id);

    return () => {
      uiStore.setCurrentPage('Loan: ' + id);
      unsubscribeLoanDetails();
      unsubscribeBorrowers();
    };
  }, [id]);

  useEffect(() => {
    const tabIndex = tabNames.indexOf(tab);
    if (tabIndex !== -1) {
      loanDetailStore.setSelectedTab(tabIndex);
    } else if (tab === undefined) {
      navigate(`/loans/${id}/application`, { replace: true });
    }
  }, [tab, id, navigate]);

  const handleTabChange = (event, newValue) => {
    loanDetailStore.setSelectedTab(newValue);
    navigate(`/loans/${id}/${tabNames[newValue]}`);
  };

  return (
    <CommonLayout>
      <Typography variant="body1">
        Last saved: {loanDetailStore.loanData?.lastUpdated && typeof loanDetailStore.loanData.lastUpdated.toDate === 'function' ? formatDistanceToNow(loanDetailStore.loanData.lastUpdated.toDate(), { addSuffix: true }) : 'Never'}
      </Typography>
      <Tabs value={loanDetailStore.selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        <Tab label="Application" />
        {loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && [
          <Tab key="balance-sheet" label="Balance Sheet" />,
          <Tab key="bsa" label="BS(a)" />,
          <Tab key="consolidated-balance-sheet" label="Consolidated BS" />,
          <Tab key="consolidated-bsa" label="Consolidated BS(a)" />,
          <Tab key="consolidated-bs-proforma" label="Consolidated BS PROFORMA" />,
          <Tab key="consolidated-bsa-proforma" label="Consolidated BS(a) PROFORMA" />,
          <Tab key="income-and-expenses" label="INC and EXP"/>,
          <Tab key="income-and-expenses-proforma" label="INC and EXP PROFORMA"/>,
          <Tab key="subsidiary-analysis" label="Subsidiary Analysis" />,
          <Tab key="loan-summary" label="Loan Summary" />
        ]}
      </Tabs>
      {loanDetailStore.selectedTab === 0 && <Application />}
      {loanDetailStore.selectedTab === 1 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <BalanceSheet />}
      {loanDetailStore.selectedTab === 2 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <BSA readOnly={false} />}
      {loanDetailStore.selectedTab === 3 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <ConsolidatedBalanceSheet />}
      {loanDetailStore.selectedTab === 4 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <BSA readOnly={true} />}
      {loanDetailStore.selectedTab === 5 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <BalanceSheetProforma/>}
      {loanDetailStore.selectedTab === 6 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <BSA readOnly={false} proforma={true} />}
      {loanDetailStore.selectedTab === 7 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <IncomeAndExpenses />}
      {loanDetailStore.selectedTab === 8 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <IncomeAndExpensesProforma />}
      {loanDetailStore.selectedTab === 9 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <SubsidiaryAnalysis />}
      {loanDetailStore.selectedTab === 10 && loanDetailStore.borrowers && loanDetailStore.borrowers.length > 0 && <LoanSummary />}
    </CommonLayout>
  );
});

export default LoanDetailView;