// TopBar.js
import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { observer } from 'mobx-react';
import { useStore } from './store'; // Adjust the path according to your project structure
import Button from '@mui/material/Button';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { db } from '../../firebase';
import { getDoc, doc } from "firebase/firestore";
import authStore from '../../authStore';
import { runInAction } from 'mobx';
import loanDetailStore from '../LoanDetailView/store';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 240, // Adjust this value based on your drawer width
    width: `calc(100% - 240px)`, // Adjust this value based on your drawer width
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const TopBar = observer(({ open, handleDrawerOpen, title, subtitle }) => {
  const { uiStore } = useStore();
  const navigate = useNavigate();
  const [creating, setCreating] = useState(false);

  const handleCreate = async () => {
    setCreating(true);
    const userDoc = await getDoc(doc(db, "Users", authStore.user.uid)); // Get the user document
    const organizationRef = userDoc.data().organizaton; // Get the organization field
    const docRef = await addDoc(collection(db, "loans"), {
      created_at: serverTimestamp(),
      created_by: authStore.user.uid,
      lending_organization: organizationRef,
      status: "Pending"
    });
    runInAction(() => {
      loanDetailStore.setSelectedTab(0);
    });
    setCreating(false);
    navigate(`/loans/${docRef.id}`);
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <div style={{ flexGrow: 1, paddingTop: "0.5em", paddingBottom: "0.5em" }}>
          <Typography variant="h4" component="div">
            {title || 'Harvest Underwriting Portal'}
          </Typography>
          <Typography variant="h6" component="div">
            {subtitle || uiStore.currentPage}
          </Typography>
        </div>
        <div>
          <Button variant="contained" color="primary" onClick={handleCreate} disabled={creating}>
            Create
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
});

export default TopBar;