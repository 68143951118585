import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';

const SubsidiaryAnalysisReport = ({ loanData, borrowers }) => {
    const subsidiaryAnalysis = loanData.subsidiaryAnalysis || {};
    const entities = subsidiaryAnalysis.entities || [];

    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
    };

    const formatPercentage = (value) => {
        return value ? `${parseFloat(value).toFixed(2)}%` : 'N/A';
    };

    const renderEntityNames = () => {
        return (
            <TableRow>
                <TableCell>Entity Names</TableCell>
                <TableCell>{borrowers.map(b => b.entityName || `${b.firstName} ${b.lastName}`).join(' - ')}</TableCell>
                {entities.map((entity, index) => (
                    <TableCell key={index}>{entity.name}</TableCell>
                ))}
            </TableRow>
        );
    };

    const renderBalanceSheetSection = () => {
        const balanceSheetFields = [
            { label: 'BS Date', field: 'bsDate' },
            { label: 'Cost BS', field: 'costBS' },
            { label: 'Ownership(%)', field: 'ownershipPercentage' },
            { label: 'Current Assets', field: 'currentAssets', format: formatCurrency },
            { label: 'Current Liabilities', field: 'currentLiabilities', format: formatCurrency },
            { label: 'Current Ratio', field: 'currentRatio' },
            { label: 'Total Assets', field: 'totalAssets', format: formatCurrency },
            { label: 'BS Elim on Con', field: 'bsElimOnCon', format: formatCurrency },
            { label: 'Total Liabilities', field: 'totalLiabilities', format: formatCurrency },
            { label: 'Net Worth', field: 'netWorth', format: formatCurrency },
            { label: 'Debt/Asset Ratio', field: 'debtAssetRatio', format: formatPercentage },
            { label: 'Ownership NW of Entity', field: 'ownershipNWOfEntity', format: formatCurrency },
        ];

        return balanceSheetFields.map(({ label, field, format }) => (
            <TableRow key={field}>
                <TableCell>{label}</TableCell>
                <TableCell>{format ? format(loanData.consolidatedBalanceSheet?.[field]) : loanData.consolidatedBalanceSheet?.[field] || 'N/A'}</TableCell>
                {entities.map((entity, index) => (
                    <TableCell key={index}>
                        {format ? format(entity.balanceSheet?.[field]) : entity.balanceSheet?.[field] || 'N/A'}
                    </TableCell>
                ))}
                <TableCell>{format ? format(subsidiaryAnalysis.consolidation?.[field]) : subsidiaryAnalysis.consolidation?.[field] || 'N/A'}</TableCell>
            </TableRow>
        ));
    };

    const renderIncomeExpenseSection = () => {
        const incomeExpenseFields = [
            { label: 'Inc/Exp Date', field: 'incExpDate' },
            { label: 'Gross Farm Income', field: 'grossFarmIncome', format: formatCurrency },
            { label: 'Farm Expense', field: 'farmExpense', format: formatCurrency },
            { label: 'Net Farm Income', field: 'netFarmIncome', format: formatCurrency },
            { label: 'Non Farm Income', field: 'nonFarmIncome', format: formatCurrency },
            { label: 'Depreciation', field: 'depreciation', format: formatCurrency },
            { label: 'Interest', field: 'interest', format: formatCurrency },
            { label: 'Leases', field: 'leases', format: formatCurrency },
            { label: 'Income Tax', field: 'incomeTax', format: formatCurrency },
            { label: 'Living', field: 'living', format: formatCurrency },
            { label: 'Inc/Exp Elim on Con', field: 'incExpElimOnCon', format: formatCurrency },
            { label: 'CFA', field: 'cfa', format: formatCurrency },
            { label: 'Debt Service', field: 'debtService', format: formatCurrency },
            { label: 'TDC', field: 'tdc' },
        ];

        return incomeExpenseFields.map(({ label, field, format }) => (
            <TableRow key={field}>
                <TableCell>{label}</TableCell>
                <TableCell>{format ? format(loanData.consolidatedIncomeExpense?.[field]) : loanData.consolidatedIncomeExpense?.[field] || 'N/A'}</TableCell>
                {entities.map((entity, index) => (
                    <TableCell key={index}>
                        {format ? format(entity.incomeExpense?.[field]) : entity.incomeExpense?.[field] || 'N/A'}
                    </TableCell>
                ))}
                <TableCell>{format ? format(subsidiaryAnalysis.consolidation?.[field]) : subsidiaryAnalysis.consolidation?.[field] || 'N/A'}</TableCell>
            </TableRow>
        ));
    };

    return (
        <Box mb={3}>
            <Typography variant="h6" gutterBottom>Subsidiary Analysis</Typography>
            <Typography>Total Number of Subsidiary Entities: {entities.length}</Typography>
            <Paper elevation={3} sx={{ mt: 2, overflowX: 'auto' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Borrower(S):</TableCell>
                            <TableCell colSpan={entities.length + 2}>{borrowers.map(b => b.entityName || `${b.firstName} ${b.lastName}`).join(' - ')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderEntityNames()}
                        {renderBalanceSheetSection()}
                        {renderIncomeExpenseSection()}
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
};

export default SubsidiaryAnalysisReport;