// src/components/LoansListView/store.js
import { makeAutoObservable, runInAction } from 'mobx';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';

class LoansStore {
  loans = [];
  loading = false;
  
  constructor() {
    makeAutoObservable(this);
  }

  setLoans(loans) {
    this.loans = loans;
  }

  extractNameFromBorrower(borrower) {
      let name;
      switch (borrower.type) {
        case 'Trust':
          name = borrower.trustName;
          break;
        case 'Entity':
          name = borrower.entityName;
          break;
        case 'Individual':
          name = `${borrower.firstName} ${borrower.lastName}`;
          break;
        default:
          name = 'Unknown';
      }
      return name;
  }

  async processLoans(querySnapshot) {
    const loans = await Promise.all(querySnapshot.docs.map(async (doc) => {
      const loanData = doc.data();
      const borrowerSnapshot = await getDocs(collection(doc.ref, 'borrowers'));
      let principalBorrower = 'N/A';
      if (borrowerSnapshot.docs.length > 0) {
        principalBorrower = this.extractNameFromBorrower(borrowerSnapshot.docs.find((doc) => doc.data().is_principal).data());
      }
      return {
        id: doc.id,
        principalBorrower: principalBorrower,
        loanAmountRequested: loanData?.application?.loanTerms?.loanAmountRequested || 'N/A',
        ...loanData,
      };
    }));
    // After fetching the loans, sort them by creation date in descending order
    loans.sort((a, b) => b.created_at?.toDate().getTime() - a.created_at?.toDate().getTime());
    return loans;
  }

  async fetchLoans() {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error('No user is signed in');
      }

      const userDoc = await getDoc(doc(db, 'Users', user.uid));
      const userOrganizationId = userDoc.data().organizaton;

      const loansCollection = collection(db, 'loans');
      const q = query(loansCollection, where('lending_organization', '==', userOrganizationId));

      const querySnapshot = await getDocs(q);
      const loans = await this.processLoans(querySnapshot);
      runInAction(() => {
        this.setLoans(loans);
      }); 
    } catch (error) {
      console.error('Error fetching loans:', error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  setupLoansListener = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('No user is signed in');
    }

    const userDoc = await getDoc(doc(db, 'Users', user.uid));
    const userOrganizationId = userDoc.data().organizaton;

    const loansCollection = collection(db, 'loans');
    const q = query(loansCollection, where('lending_organization', '==', userOrganizationId));

    return onSnapshot(q, async (snapshot) => {
      const loans = await this.processLoans(snapshot);
      this.setLoans(loans);
    });
  };
  async deleteLoan(loanId) {
    try {
      await deleteDoc(doc(db, 'loans', loanId));
      runInAction(() => {
        this.loans = this.loans.filter(loan => loan.id !== loanId);
      });
    } catch (error) {
      console.error('Error deleting loan:', error);
    }
  }
}

const loansStore = new LoansStore();
export default loansStore;