import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Box, Grid } from '@mui/material';
import {
    calculateTotalCurrentAssets,
    calculateTotalIntermediateAssets,
    calculateTotalLongTermAssets
} from './balanceSheetUtils';
import loanDetailStore from '../store';

const TotalAssets = observer(({ borrowers, consolidated, proforma }) => {
    const mode = consolidated ? 'consolidated' : (proforma ? 'proforma' : 'individual');
    const { loanData } = loanDetailStore;

    const totalCurrentAssets = calculateTotalCurrentAssets(borrowers, mode, loanData);
    const totalIntermediateAssets = calculateTotalIntermediateAssets(borrowers, mode, loanData);
    const totalLongTermAssets = calculateTotalLongTermAssets(borrowers, mode, loanData);

    const totalNonCurrentAssets = totalIntermediateAssets + totalLongTermAssets;
    const totalAssets = totalCurrentAssets + totalIntermediateAssets + totalLongTermAssets;

    return (
        <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Total Current Assets</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {totalCurrentAssets.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Typography>
                </Grid>
            </Box>
            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Total Non-Current Assets</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {totalNonCurrentAssets.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Typography>
                </Grid>
            </Box>

            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Total Assets</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {totalAssets.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Typography>
                </Grid>
            </Box>
        </Grid>
    );
});

export default TotalAssets;