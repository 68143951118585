import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import loanDetailStore from '../store';

const SubsidiaryAnalysis = observer(() => {
  const {
    loanData,
    updateSubsidiaryAnalysis,
    initializeSubsidiaryAnalysis
  } = loanDetailStore;

  const subsidiaryAnalysis = loanData.subsidiaryAnalysis || {};
  const subsidiaryCount = subsidiaryAnalysis.count || 0;

  useEffect(() => {
    if (!subsidiaryAnalysis.initialized) {
      initializeSubsidiaryAnalysis();
    }
  }, [subsidiaryAnalysis.initialized, initializeSubsidiaryAnalysis]);

  const handleSubsidiaryCountChange = (event) => {
    const count = Number(event.target.value);
    updateSubsidiaryAnalysis('count', count);
    updateSubsidiaryAnalysis('initialized', true);
  };

  const handleSubsidiaryEntityChange = (index, field, value) => {
    updateSubsidiaryAnalysis(`entities.${index}.${field}`, value);
  };

  const renderSubsidiaryEntities = () => {
    return Array(subsidiaryCount)
        .fill()
        .map((_, index) => (
            <Grid container key={index} spacing={2}>
              <Grid item xs={6}>
                <TextField
                    label="Entity Name"
                    value={subsidiaryAnalysis.entities?.[index]?.name || ''}
                    onChange={(event) => handleSubsidiaryEntityChange(index, 'name', event.target.value)}
                    fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    label="Tax ID"
                    value={subsidiaryAnalysis.entities?.[index]?.taxId || ''}
                    onChange={(event) => handleSubsidiaryEntityChange(index, 'taxId', event.target.value)}
                    fullWidth
                />
              </Grid>
            </Grid>
        ));
  };

  const renderBalanceSheetRows = () => {
    const balanceSheetFields = [
      'BS Data',
      'Cash BS',
      'Overdrafts %',
      'Current Assets',
      'Current Liabilities',
      'Current Ratio',
      'Total Assets',
      'BS External Debt',
      'Total Liabilities',
      'Net Worth',
      'Debt/Asset Ratio',
      'Ownership %',
      'Ownership NW of Entity',
    ];

    return balanceSheetFields.map((field) => (
        <TableRow key={field}>
          <TableCell>{field}</TableCell>
          <TableCell>{loanData.consolidatedBalanceSheet?.[field] || ''}</TableCell>
          {Array(subsidiaryCount).fill().map((_, index) => (
              <TableCell key={index}>
                <TextField
                    value={subsidiaryAnalysis.entities?.[index]?.balanceSheet?.[field] || ''}
                    onChange={(event) =>
                        handleSubsidiaryEntityChange(index, `balanceSheet.${field}`, event.target.value)
                    }
                    fullWidth
                />
              </TableCell>
          ))}
          <TableCell>{calculateConsolidationByOwnership(field)}</TableCell>
        </TableRow>
    ));
  };

  const renderIncomeExpenseRows = () => {
    const incomeExpenseFields = [
      'Inc/Exp Data',
      'Gross Farm Income',
      'Farm Expense',
      'Net Farm Income',
      'Non Farm Income',
      'Depreciation',
      'Interest',
      'Lesses',
      'Income Tax',
      'Living',
      'Inc/Exp Elin on Con',
      'DDA',
      'Debt Service',
      'TDC',
    ];

    return incomeExpenseFields.map((field) => (
        <TableRow key={field}>
          <TableCell>{field}</TableCell>
          <TableCell>{loanData.consolidatedIncomeExpense?.[field] || ''}</TableCell>
          {Array(subsidiaryCount).fill().map((_, index) => (
              <TableCell key={index}>
                <TextField
                    value={subsidiaryAnalysis.entities?.[index]?.incomeExpense?.[field] || ''}
                    onChange={(event) =>
                        handleSubsidiaryEntityChange(index, `incomeExpense.${field}`, event.target.value)
                    }
                    fullWidth
                />
              </TableCell>
          ))}
          <TableCell>{calculateConsolidationByOwnership(field)}</TableCell>
        </TableRow>
    ));
  };

  const calculateConsolidationByOwnership = (field) => {
    const consolidatedValue = loanData.consolidatedBalanceSheet?.[field] || loanData.consolidatedIncomeExpense?.[field] || 0;
    const subsidiaryValues = subsidiaryAnalysis.entities?.map((entity) => parseFloat(entity?.balanceSheet?.[field] || entity?.incomeExpense?.[field] || 0)) || [];
    const ownershipPercentages = subsidiaryAnalysis.entities?.map((entity) => parseFloat(entity?.balanceSheet?.['Ownership %'] || 0) / 100) || [];

    const consolidatedSubsidiaryValue = subsidiaryValues.reduce((sum, value, index) => sum + value * ownershipPercentages[index], 0);

    return consolidatedValue + consolidatedSubsidiaryValue;
  };

  return (
      <Box>
        <Typography variant="h6">Subsidiary Analysis</Typography>
        <Box mt={2}>
          <TextField
              select
              label="Total Number of Subsidiary Entities"
              value={subsidiaryCount}
              onChange={handleSubsidiaryCountChange}
              fullWidth
              disabled={subsidiaryAnalysis.initialized}
          >
            {Array(100)
                .fill()
                .map((_, index) => (
                    <MenuItem key={index} value={index}>
                      {index}
                    </MenuItem>
                ))}
          </TextField>
        </Box>
        <Box mt={2}>{renderSubsidiaryEntities()}</Box>
        <Box mt={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Balance Sheet</TableCell>
                  <TableCell>Borrower</TableCell>
                  {Array(subsidiaryCount).fill().map((_, index) => (
                      <TableCell key={index}>{subsidiaryAnalysis.entities?.[index]?.name || `Entity ${index + 1}`}</TableCell>
                  ))}
                  <TableCell>Consolidation by % Ownership</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderBalanceSheetRows()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mt={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Income/Expense</TableCell>
                  <TableCell>Borrower</TableCell>
                  {Array(subsidiaryCount).fill().map((_, index) => (
                      <TableCell key={index}>{subsidiaryAnalysis.entities?.[index]?.name || `Entity ${index + 1}`}</TableCell>
                  ))}
                  <TableCell>Consolidation by % Ownership</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderIncomeExpenseRows()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
  );
});

export default SubsidiaryAnalysis;