// theme.js

import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007AFF',
    },
    secondary: {
      main: '#F5F5F5',
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: '#1A1F24',
      secondary: '#8B8B8B',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '20px',
          },
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: '#ededed', // Change this to the color you want for disabled (read-only) text fields
          },
          '& .MuiInputBase-root.MuiInputBase-readOnly': {
            backgroundColor: '#ededed', // Change this to the color you want for read-only text fields
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontFamily: 'Google Sans, sans-serif',
    },
    h2: {
      fontFamily: 'Google Sans, sans-serif',
    },
    h3: {
      fontFamily: 'Google Sans, sans-serif',
    },
    h4: {
      fontFamily: 'Google Sans, sans-serif',
    },
    h5: {
      fontFamily: 'Google Sans, sans-serif',
    },
    h6: {
      fontFamily: 'Google Sans, sans-serif',
    },
  },
});

export default theme;