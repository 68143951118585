// IntermediateLiabilities.js
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Box, Grid } from '@mui/material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const IntermediateLiabilities = observer(({ borrowers, consolidated }) => {
  const calculateArrayFieldSum = (schedule, field) => {
    return borrowers.reduce((sum, borrower) => {
      const array = borrower.bsas?.[schedule] || [];
      const arraySum = array.reduce((innerSum, item) => {
        const value = parseFloat(item?.[field] || 0);
        return innerSum + value;
      }, 0);
      return sum + arraySum;
    }, 0);
  };

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="subtitle1" my={2}>
        Intermediate Liabilities
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <CurrencyTextField
          variant="outlined"
          label="Chattel Debt and Notes Payable (Schedule B)"
          value={calculateArrayFieldSum('b', 'balance')}
          InputProps={{ readOnly: true }}
        />
        <CurrencyTextField
          variant="outlined"
          label="Less Current Portion (Schedule B)"
          value={-calculateArrayFieldSum('b', 'annualPayment')}
          InputProps={{ readOnly: true }}
        />
        <Box display="flex" flexDirection="row" gap={2} my={2}>
          <Grid item xs={12} md={6}>
            <Typography>Total Intermediate Liabilities:</Typography>
          </Grid>
          <Grid item xs={12} md={6} align="right">
            <Typography>
              {(
                calculateArrayFieldSum('b', 'balance') -
                calculateArrayFieldSum('b', 'annualPayment')
              ).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
            </Typography>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
});

export default IntermediateLiabilities;