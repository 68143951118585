import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import LoginComponent from './components/Login/view';
import DashboardComponent from './components/Dashboard/view';
import authStore from './authStore';
import theme from './theme';
import LoansListView from './components/LoanListView/view';
import { ThemeProvider } from '@mui/material';
import LoanDetailView from './components/LoanDetailView/view';
import MultipleTabsModal from './components/GlobalComponents/MultipleTabsModal';
import AuthWrapper from './AuthWrapper';

const App = observer(() => {
  return (
    <ThemeProvider theme={theme}>
      <MultipleTabsModal />
      <AuthWrapper>
        <Routes>
          <Route path="/" element={authStore.isLoggedIn ? <Navigate to="/dashboard" replace /> : <LoginComponent />} />
          <Route path="/loans" element={authStore.isLoggedIn ? <LoansListView /> : <Navigate to="/" replace />} />
          <Route path="/loans/:id" element={authStore.isLoggedIn ? <LoanDetailView /> : <Navigate to="/" replace />}>
            <Route path=":tab" element={<LoanDetailView />} />
          </Route>
          <Route path="/dashboard" element={authStore.isLoggedIn ? <DashboardComponent /> : <Navigate to="/" replace />} />
        </Routes>
      </AuthWrapper>
    </ThemeProvider>
  );
});

export default App;