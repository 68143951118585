import React, {useCallback, useMemo} from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const ScheduleF = observer(({ borrowerData, readOnly, proforma }) => {
  const { updateBSAData, addBSAItem, deleteBSAItem, updateBSAProformaData, addBSAProformaItem, deleteBSAProformaItem, selectedBorrowerId } = loanDetailStore;

  const selectedBorrowerLocalId = useMemo(() => {
    let result;
    if (readOnly || proforma) {
      result = borrowerData.find(borrower => borrower.is_principal === true)["id"];
    } else {
      result = selectedBorrowerId;
    }
    if (!result) {
      console.warn('No borrower found!');
    }
    return result;
  }, [selectedBorrowerId, readOnly, proforma, borrowerData]);

  const selectedBorrower = useMemo(() => {
    return borrowerData.find(borrower => borrower.id === selectedBorrowerLocalId);
  }, [selectedBorrowerLocalId, borrowerData]);

  const handleUpdateItem = useCallback((borrowerId, index, field, value) => {
    if (proforma) {
      updateBSAProformaData(borrowerId, 'f', index, field, value);
    } else {
      updateBSAData(borrowerId, 'f', index, field, value);
    }
  }, [updateBSAData, updateBSAProformaData, proforma]);

  const handleAddItem = useCallback(() => {
    if (proforma) {
      addBSAProformaItem('f');
    } else {
      addBSAItem(selectedBorrowerLocalId, 'f');
    }
  }, [addBSAItem, addBSAProformaItem, proforma, selectedBorrowerLocalId]);

  const handleDeleteItem = useCallback((borrowerId, index) => {
    if (proforma) {
      deleteBSAProformaItem(borrowerId, 'f', index);
    } else {
      deleteBSAItem(borrowerId, 'f', index);
    }
  }, [deleteBSAItem, deleteBSAProformaItem, proforma]);

  const getFieldValue = useCallback((item, field) => {
    return proforma ? (item.proforma?.[field] ?? item[field] ?? '') : (item[field] ?? '');
  }, [proforma]);

  const renderScheduleItems = () => {
    if (readOnly) {
      return borrowerData.flatMap(borrower => borrower.bsas?.f || []);
    }

    if (proforma) {
      return borrowerData.flatMap(borrower =>
          (borrower.bsas?.f || []).map(item => ({
            ...item,
            ...(item.proforma || {}),
            borrowerId: borrower.id,
          }))
      );
    }

    return (selectedBorrower.bsas?.f || []).map((item, index) => ({ ...item, index }));
  };

  const scheduleItems = renderScheduleItems();

  const calculateTotals = () => {
    return scheduleItems.reduce((totals, item) => {
      const acres = parseFloat(getFieldValue(item, 'acres')) || 0;
      const costPerAcre = parseFloat(getFieldValue(item, 'costPerAcre')) || 0;
      totals.acres += acres;
      totals.principal += parseFloat(getFieldValue(item, 'principal')) || 0;
      totals.value += acres * costPerAcre;
      return totals;
    }, { acres: 0, principal: 0, value: 0 });
  };

  const totals = calculateTotals();

  return (
      <>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Schedule F - Growing Crops (At Cost)</Typography>
        {scheduleItems.map((item, index) => (
            <Box key={index} mb={2}>
              <Box display="flex" gap={2} mb={1}>
                <TextField
                    label="Crop"
                    value={getFieldValue(item, 'crop')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'crop', e.target.value)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Acres"
                    type="number"
                    value={getFieldValue(item, 'acres')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'acres', parseFloat(e.target.value) || '')}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    variant="outlined"
                    label="Cost Per Acre"
                    value={getFieldValue(item, 'costPerAcre')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'costPerAcre', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    variant="outlined"
                    label="Principal"
                    value={getFieldValue(item, 'principal')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'principal', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
                    variant="outlined"
                    label="Value"
                    value={(parseFloat(getFieldValue(item, 'acres')) || 0) * (parseFloat(getFieldValue(item, 'costPerAcre')) || 0)}
                    InputProps={{ readOnly: true }}
                />

              {(!readOnly && !proforma) && (
                  <IconButton onClick={() => handleDeleteItem(item.borrowerId || selectedBorrowerLocalId, index)}>
                    <DeleteIcon />
                  </IconButton>
              )}
              </Box>
            </Box>
        ))}
        {(!readOnly && !proforma) && (
            <Box display="flex" justifyContent="flex-start" mb={2}>
              <Button variant="contained" onClick={handleAddItem}>
                Add Item
              </Button>
            </Box>
        )}
        {(readOnly || proforma) && (
            <Box display="flex" gap={2} my={3} justifyContent="flex-end">
              <TextField
                  variant="outlined"
                  label="Total Acres"
                  value={totals.acres}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
              <CurrencyTextField
                  variant="outlined"
                  label="Total Principal"
                  value={totals.principal}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
              <CurrencyTextField
                  variant="outlined"
                  label="Total Value"
                  value={totals.value}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
            </Box>
        )}
      </>
  );
});

export default ScheduleF;