// store.js

import { makeAutoObservable } from 'mobx';

class UIStore {
  collapsed = false;
  darkMode = false;
  currentPage = '';
  open = true;
  tabValue = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setCollapsed(collapsed) {
    this.collapsed = collapsed;
  }

  setDarkMode(darkMode) {
    this.darkMode = darkMode;
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  setOpen(open) {
    this.open = open;
  }

  setTabValue(tabValue) {
    this.tabValue = tabValue;
  }
}

const uiStore = new UIStore();

export const useStore = () => ({
  uiStore,
});

export default uiStore;