import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
} from '@mui/material';

const DeleteConfirmationModal = ({ open, onClose, onConfirm, principalBorrower }) => {
    const [confirmationText, setConfirmationText] = useState('');

    const handleConfirm = () => {
        if (confirmationText === principalBorrower) {
            onConfirm();
            setConfirmationText('');
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To confirm the deletion of this loan, please type the name of the principal borrower:
                    <strong> {principalBorrower}</strong>
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Principal Borrower Name"
                    fullWidth
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={handleConfirm}
                    color="primary"
                    disabled={confirmationText !== principalBorrower}
                >
                    Confirm Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationModal;