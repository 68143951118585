// balanceSheetUtils.js

export const calculateArrayFieldSum = (borrowers, schedule, field) => {
    return borrowers.reduce((sum, borrower) => {
        const array = (borrower?.bsas || {})[schedule] || [];
        const arraySum = array.reduce((innerSum, item) => {
            const value = parseFloat(item?.[field] || 0);
            return innerSum + value;
        }, 0);
        return sum + arraySum;
    }, 0);
};

export const calculateCurrentTotal = (borrowers, assetOrLiability, field) => {
    return borrowers.reduce((sum, borrower) => {
        const value = parseFloat(borrower.balanceSheet?.[assetOrLiability]?.[field]) || 0;
        return sum + value;
    }, 0);
};

export const calculateOtherItemsConsolidatedTotal = (borrowers, category) => {
    return borrowers.reduce((sum, borrower) => {
        const items = borrower.balanceSheet?.[category] || [];
        const itemsSum = items.reduce((innerSum, item) => {
            const value = parseFloat(item.amount || 0) - parseFloat(item.elims || 0);
            return innerSum + value;
        }, 0);
        return sum + itemsSum;
    }, 0);
};

export const calculateOtherItemsProformaTotal = (borrowers, category) => {
    return borrowers.reduce((sum, borrower) => {
        const items = borrower.balanceSheet?.[category] || [];
        const itemsSum = items.reduce((innerSum, item) => {
            const value = parseFloat(item.amount || 0) - parseFloat(item.elims || 0) + parseFloat(item.change || 0);
            return innerSum + value;
        }, 0);
        return sum + itemsSum;
    }, 0);
};

export const calculateTotalCurrentAssets = (borrowers, mode, loanData) => {
    const baseTotal =
        calculateCurrentTotal(borrowers, 'currentAssets', 'cash') +
        calculateCurrentTotal(borrowers, 'currentAssets', 'savings') +
        calculateCurrentTotal(borrowers, 'currentAssets', 'marketableBondsAndSecurities') +
        calculateCurrentTotal(borrowers, 'currentAssets', 'cashValueLifeInsurance') +
        calculateCurrentTotal(borrowers, 'currentAssets', 'prepaidExpensesAndSupplies') +
        calculateArrayFieldSum(borrowers, 'd', 'amount') +
        calculateArrayFieldSum(borrowers, 'e', 'value') -
        calculateArrayFieldSum(borrowers, 'e', 'interest') +
        calculateArrayFieldSum(borrowers, 'g', 'value') +
        calculateArrayFieldSum(borrowers, 'f', 'value') +
        calculateArrayFieldSum(borrowers, 'i', 'value');

    if (mode === 'individual') {
        return baseTotal + calculateOtherItemsConsolidatedTotal(borrowers, 'otherCurrentAssets');
    }

    const consolidatedBalance = loanData.consolidatedBalance?.currentAssets || {};
    const elims =
        (consolidatedBalance.cashElims || 0) +
        (consolidatedBalance.savingsElims || 0) +
        (consolidatedBalance.marketableBondsAndSecuritiesElims || 0) +
        (consolidatedBalance.cashValueLifeInsuranceElims || 0) +
        (consolidatedBalance.prepaidExpensesAndSuppliesElims || 0) +
        (consolidatedBalance.accountsReceivableScheduleDElims || 0) +
        (consolidatedBalance.currentPortionNotesReceivableScheduleEElims || 0) +
        (consolidatedBalance.harvestedCropsScheduleGElims || 0) +
        (consolidatedBalance.growingCropsAtCostScheduleFElims || 0) +
        (consolidatedBalance.livestockHeldForSaleAtMarketScheduleIElims || 0);

    if (mode === 'consolidated') {
        return baseTotal - elims + calculateOtherItemsConsolidatedTotal(borrowers, 'otherCurrentAssets');
    }

    const proformaBalance = loanData.balanceSheetProforma?.currentAssets || {};
    const changes =
        (proformaBalance.cashChange || 0) +
        (proformaBalance.savingsChange || 0) +
        (proformaBalance.marketableBondsAndSecuritiesChange || 0) +
        (proformaBalance.cashValueLifeInsuranceChange || 0) +
        (proformaBalance.prepaidExpensesAndSuppliesChange || 0) +
        (proformaBalance.accountsReceivableScheduleDChange || 0) +
        (proformaBalance.currentPortionNotesReceivableScheduleEChange || 0) +
        (proformaBalance.harvestedCropsScheduleGChange || 0) +
        (proformaBalance.growingCropsAtCostScheduleFChange || 0) +
        (proformaBalance.livestockHeldForSaleAtMarketScheduleIChange || 0);

    return baseTotal - elims + changes + calculateOtherItemsProformaTotal(borrowers, 'otherCurrentAssets');
};

export const calculateTotalIntermediateAssets = (borrowers, mode, loanData) => {
    const baseTotal =
        calculateArrayFieldSum(borrowers, 'h', 'value') +
        calculateArrayFieldSum(borrowers, 'k', 'marketValue') +
        calculateArrayFieldSum(borrowers, 'e', 'principal') +
        calculateArrayFieldSum(borrowers, 'e', 'interest') -
        calculateArrayFieldSum(borrowers, 'e', 'value');

    if (mode === 'individual') {
        return baseTotal + calculateOtherItemsConsolidatedTotal(borrowers, 'otherNonCurrentAssets') + calculateOtherItemsConsolidatedTotal(borrowers, 'otherInvestments');
    }

    const consolidatedBalance = loanData.consolidatedBalance?.intermediateAssets || {};
    const elims =
        (consolidatedBalance.livestockBreedingElims || 0) +
        (consolidatedBalance.machineryAndVehiclesElims || 0) +
        (consolidatedBalance.notesAndContractsReceivableElims || 0) -
        (consolidatedBalance.lessCurrentPortionElims || 0);

    if (mode === 'consolidated') {
        return baseTotal - elims + calculateOtherItemsConsolidatedTotal(borrowers, 'otherNonCurrentAssets') + calculateOtherItemsConsolidatedTotal(borrowers, 'otherInvestments');
    }

    const proformaBalance = loanData.balanceSheetProforma?.intermediateAssets || {};
    const changes =
        (proformaBalance.livestockBreedingChange || 0) +
        (proformaBalance.machineryAndVehiclesChange || 0) +
        (proformaBalance.notesAndContractsReceivableChange || 0) -
        (proformaBalance.lessCurrentPortionChange || 0);

    return baseTotal - elims + changes + calculateOtherItemsProformaTotal(borrowers, 'otherNonCurrentAssets') + calculateOtherItemsProformaTotal(borrowers, 'otherInvestments');
};

export const calculateTotalLongTermAssets = (borrowers, mode, loanData) => {
    const baseTotal = calculateArrayFieldSum(borrowers, 'a', 'marketValue');

    if (mode === 'individual') {
        return baseTotal + calculateOtherItemsConsolidatedTotal(borrowers, 'otherNonRealEstateLongTermAssets');
    }

    const consolidatedBalance = loanData.consolidatedBalance?.longTermAssets || {};
    const elims = consolidatedBalance.realEstateOwnedElims || 0;

    if (mode === 'consolidated') {
        return baseTotal - elims + calculateOtherItemsConsolidatedTotal(borrowers, 'otherNonRealEstateLongTermAssets');
    }

    const proformaBalance = loanData.balanceSheetProforma?.longTermAssets || {};
    const changes = proformaBalance.realEstateOwnedChange || 0;

    return baseTotal - elims + changes + calculateOtherItemsProformaTotal(borrowers, 'otherNonRealEstateLongTermAssets');
};

// balanceSheetUtils.js

// ... (keep the existing functions from the previous update)

export const calculateTotalCurrentLiabilities = (borrowers, mode, loanData) => {
    const baseTotal =
        calculateArrayFieldSum(borrowers, 'c', 'balance') +
        calculateCurrentTotal(borrowers, 'currentLiabilities', 'incomeTaxPayable') +
        calculateCurrentTotal(borrowers, 'currentLiabilities', 'realEstateTaxesDue') +
        calculateCurrentTotal(borrowers, 'currentLiabilities', 'rentsDue') +
        calculateAccruedInterestSum(borrowers) +
        calculateCurrentMaturitySum(borrowers);

    if (mode === 'individual') {
        return baseTotal + calculateOtherItemsConsolidatedTotal(borrowers, 'accountsPayable');
    }

    const consolidatedBalance = loanData.consolidatedBalance?.currentLiabilities || {};
    const elims =
        (consolidatedBalance.incomeTaxPayableElims || 0) +
        (consolidatedBalance.realEstateTaxesDueElims || 0) +
        (consolidatedBalance.rentsDueElims || 0);

    if (mode === 'consolidated') {
        return baseTotal - elims + calculateOtherItemsConsolidatedTotal(borrowers, 'accountsPayable');
    }

    const proformaBalance = loanData.balanceSheetProforma?.currentLiabilities || {};
    const changes =
        (proformaBalance.incomeTaxPayableChange || 0) +
        (proformaBalance.realEstateTaxesDueChange || 0) +
        (proformaBalance.rentsDueChange || 0);

    return baseTotal - elims + changes + calculateOtherItemsProformaTotal(borrowers, 'accountsPayable');
};

export const calculateTotalIntermediateLiabilities = (borrowers, mode, loanData) => {
    const baseTotal = calculateArrayFieldSum(borrowers, 'b', 'balance') - calculateArrayFieldSum(borrowers, 'b', 'annualPayment');

    if (mode === 'individual') {
        return baseTotal;
    }

    const consolidatedBalance = loanData.consolidatedBalance?.intermediateLiabilities || {};
    const elims = consolidatedBalance.chattelDebtAndNotesPayableElims || 0;

    if (mode === 'consolidated') {
        return baseTotal - elims;
    }

    const proformaBalance = loanData.balanceSheetProforma?.intermediateLiabilities || {};
    const changes = proformaBalance.chattelDebtAndNotesPayableChange || 0;

    return baseTotal - elims + changes;
};

export const calculateTotalLongTermLiabilities = (borrowers, mode, loanData) => {
    const baseTotal = calculateArrayFieldSum(borrowers, 'a', 'balance') - calculateArrayFieldSum(borrowers, 'a', 'annualPayment');

    if (mode === 'individual') {
        return baseTotal;
    }

    const consolidatedBalance = loanData.consolidatedBalance?.longTermLiabilities || {};
    const elims = consolidatedBalance.realEstateMortgagesElims || 0;

    if (mode === 'consolidated') {
        return baseTotal - elims;
    }

    const proformaBalance = loanData.balanceSheetProforma?.longTermLiabilities || {};
    const changes = proformaBalance.realEstateMortgagesChange || 0;

    return baseTotal - elims + changes;
};

const calculateAccruedInterestSum = (borrowers) => {
    return ['a', 'b', 'c'].reduce((sum, schedule) => {
        return sum + calculateArrayFieldSum(borrowers, schedule, 'accruedInterest');
    }, 0);
};

const calculateCurrentMaturitySum = (borrowers) => {
    return ['a', 'b'].reduce((sum, schedule) => {
        return sum + calculateArrayFieldSum(borrowers, schedule, 'annualPayment');
    }, 0);
};