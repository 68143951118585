import React from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import store from '../Login/store';

const MultipleTabsModal = observer(() => {
    const handleClose = () => {
        store.setMultipleTabsOpen(false);
    };

    return (
        <Dialog open={store.multipleTabsOpen} onClose={handleClose}>
            <DialogTitle>Multiple tabs open</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Only one tab can be open at a time to save to the database.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default MultipleTabsModal;