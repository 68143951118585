import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid } from '@mui/material';
import loanDetailStore from '../store';
import CurrentAssets from './CurrentAssets';
import CurrentLiabilities from './CurrentLiabilities';
import IntermediateAssets from './IntermediateAssets';
import IntermediateLiabilities from './IntermediateLiabilities';
import LongTermAssets from './LongTermAssets';
import LongTermLiabilities from './LongTermLiabilities';
import TotalAssets from './TotalAssets';
import TotalLiabilities from './TotalLiabilities';

const ConsolidatedBalanceSheet = observer(() => {
  const { borrowers } = loanDetailStore;

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" mb={2}>
      <Typography variant="h6" mb={1}>
        Consolidated Balance Sheet
      </Typography>
      <Grid container spacing={4}>
        <CurrentAssets borrowers={borrowers} consolidated />
        <CurrentLiabilities borrowers={borrowers} consolidated />
        <IntermediateAssets borrowers={borrowers} consolidated />
        <IntermediateLiabilities borrowers={borrowers} consolidated />
        <LongTermAssets borrowers={borrowers} consolidated />
        <LongTermLiabilities borrowers={borrowers} consolidated />
        <TotalAssets borrowers={borrowers} />
        <TotalLiabilities borrowers={borrowers} />
      </Grid>
    </Box>
  );
});

export default ConsolidatedBalanceSheet;