import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const ConsolidatedBalanceSheet = observer(({ loanData }) => {
    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
    };

    const balanceSheet = loanData?.consolidatedBalance || {};

    const renderAssetSection = (title, items) => (
        <>
            <TableRow>
                <TableCell colSpan={2}><Typography variant="subtitle1">{title}</Typography></TableCell>
            </TableRow>
            {items.map((item, index) => (
                <TableRow key={index}>
                    <TableCell>{item.label}</TableCell>
                    <TableCell align="right">{formatCurrency(item.value)}</TableCell>
                </TableRow>
            ))}
        </>
    );

    const renderLiabilitySection = (title, items) => (
        <>
            <TableRow>
                <TableCell colSpan={2}><Typography variant="subtitle1">{title}</Typography></TableCell>
            </TableRow>
            {items.map((item, index) => (
                <TableRow key={index}>
                    <TableCell>{item.label}</TableCell>
                    <TableCell align="right">{formatCurrency(item.value)}</TableCell>
                </TableRow>
            ))}
        </>
    );

    const currentAssets = [
        { label: 'Cash', value: balanceSheet.currentAssets?.cash },
        { label: 'Savings', value: balanceSheet.currentAssets?.savings },
        { label: 'Marketable Bonds & Securities', value: balanceSheet.currentAssets?.marketableBondsAndSecurities },
        { label: 'Cash Value Life Insurance', value: balanceSheet.currentAssets?.cashValueLifeInsurance },
        { label: 'Prepaid Expenses & Supplies', value: balanceSheet.currentAssets?.prepaidExpensesAndSupplies },
        { label: 'Accounts Receivable (Schedule D)', value: balanceSheet.currentAssets?.accountsReceivableScheduleD },
        { label: 'Current Portion Notes Receivable (Schedule E)', value: balanceSheet.currentAssets?.currentPortionNotesReceivableScheduleE },
        { label: 'Harvested Crops (Schedule G)', value: balanceSheet.currentAssets?.harvestedCropsScheduleG },
        { label: 'Growing Crops (at cost) (Schedule F)', value: balanceSheet.currentAssets?.growingCropsAtCostScheduleF },
        { label: 'Livestock held for sale (at market) (Schedule I)', value: balanceSheet.currentAssets?.livestockHeldForSaleAtMarketScheduleI },
    ];

    const intermediateAssets = [
        { label: 'Livestock - Breeding (Schedule H)', value: balanceSheet.intermediateAssets?.livestockBreeding },
        { label: 'Machinery and Vehicles (Schedule K)', value: balanceSheet.intermediateAssets?.machineryAndVehicles },
        { label: 'Notes and Contracts Receivable (Schedule E)', value: balanceSheet.intermediateAssets?.notesAndContractsReceivable },
        { label: 'Less Current Portion (Schedule E)', value: balanceSheet.intermediateAssets?.lessCurrentPortion },
    ];

    const longTermAssets = [
        { label: 'Real Estate Owned (Schedule A)', value: balanceSheet.longTermAssets?.realEstateOwned },
    ];

    const currentLiabilities = [
        { label: 'Current Debt - Mat. L.S. Corp Credit (Schedule C)', value: balanceSheet.currentLiabilities?.currentDebtMatLSCorpCredit },
        { label: 'Income Tax Payable', value: balanceSheet.currentLiabilities?.incomeTaxPayable },
        { label: 'Real Estate Taxes Due', value: balanceSheet.currentLiabilities?.realEstateTaxesDue },
        { label: 'Rents Due', value: balanceSheet.currentLiabilities?.rentsDue },
        { label: 'Accrued Interest (A,B,C)', value: balanceSheet.currentLiabilities?.accruedInterest },
        { label: 'Current Mat. of Non-Curr. Debt (A,B)', value: balanceSheet.currentLiabilities?.currentMatOfNonCurrDebt },
    ];

    const intermediateLiabilities = [
        { label: 'Chattel Debt & Notes Payable (Schedule B)', value: balanceSheet.intermediateLiabilities?.chattelDebtAndNotesPayable },
        { label: 'Less Current Portion (Schedule B)', value: balanceSheet.intermediateLiabilities?.lessCurrentPortion },
    ];

    const longTermLiabilities = [
        { label: 'Real Estate Mortgages (Schedule A)', value: balanceSheet.longTermLiabilities?.realEstateMortgages },
        { label: 'Less Current Portion (Schedule A)', value: balanceSheet.longTermLiabilities?.lessCurrentPortion },
    ];

    const totalAssets = balanceSheet.totalAssets;
    const totalLiabilities = balanceSheet.totalLiabilities;
    const netWorth = totalAssets - totalLiabilities;

    return (
        <Box mb={3}>
            <Typography variant="h5" gutterBottom>Consolidated Balance Sheet</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="right">Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderAssetSection('Current Assets', currentAssets)}
                    {renderAssetSection('Intermediate Assets', intermediateAssets)}
                    {renderAssetSection('Long Term Assets', longTermAssets)}
                    <TableRow>
                        <TableCell><strong>Total Assets</strong></TableCell>
                        <TableCell align="right"><strong>{formatCurrency(totalAssets)}</strong></TableCell>
                    </TableRow>
                    {renderLiabilitySection('Current Liabilities', currentLiabilities)}
                    {renderLiabilitySection('Intermediate Liabilities', intermediateLiabilities)}
                    {renderLiabilitySection('Long Term Liabilities', longTermLiabilities)}
                    <TableRow>
                        <TableCell><strong>Total Liabilities</strong></TableCell>
                        <TableCell align="right"><strong>{formatCurrency(totalLiabilities)}</strong></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><strong>Net Worth</strong></TableCell>
                        <TableCell align="right"><strong>{formatCurrency(netWorth)}</strong></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
});

export default ConsolidatedBalanceSheet;