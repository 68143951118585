import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid } from '@mui/material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const IncomeStatementTrendAnalysis = observer(() => {
    const { borrowers, loanData } = loanDetailStore;
    const selectedBorrower = borrowers.find(b => b.is_principal) || {};
    const incomeAndExpenses = selectedBorrower.incomeAndExpenses || {};

    // Generate years dynamically
    const mostRecentYear = incomeAndExpenses.mostRecentTaxYear || new Date().getFullYear();
    const years = Array.from({ length: incomeAndExpenses.years || 4 }, (_, i) => (mostRecentYear - i).toString());
    years.reverse(); // Reverse to show oldest to newest
    years.push('Average', 'Pro-Forma');

    const calculateAverage = (field) => {
        const sum = incomeAndExpenses.data?.reduce((acc, year) => acc + (parseFloat(year[field]) || 0), 0) || 0;
        return (sum / (incomeAndExpenses.years || 1)).toFixed(2);
    };

    const calculateNetFarmIncome = (index) => {
        const data = incomeAndExpenses.data?.[index] || {};
        return (parseFloat(data.totalFarmIncome) || 0) - (parseFloat(data.totalFarmExpenses) || 0);
    };

    const calculateCashFlow = (index) => {
        const data = incomeAndExpenses.data?.[index] || {};
        return (
            calculateNetFarmIncome(index) +
            (parseFloat(data.netNonFarmIncome) || 0) +
            (parseFloat(data.depreciation) || 0) +
            (parseFloat(data.totalInterest) || 0) +
            (parseFloat(data.capitalLease) || 0) +
            (parseFloat(data.otherPlusAdjustment) || 0) -
            (parseFloat(data.incomeTax) || 0) -
            (parseFloat(data.familyLiving) || 0) -
            (parseFloat(data.nonRecurring) || 0) -
            (parseFloat(data.otherMinusAdjustment) || 0)
        );
    };

    const renderRow = (label, field, calculate = false) => (
        <>
            <Grid item xs={3}>
                <Typography>{label}</Typography>
            </Grid>
            {years.map((year, index) => (
                <Grid item xs={1.5} key={year}>
                    <CurrencyTextField
                        value={
                            year === 'Average'
                                ? calculateAverage(field)
                                : year === 'Pro-Forma'
                                    ? loanData.incomeAndExpensesProforma?.[field] || ''
                                    : calculate
                                        ? field === 'cashFlow'
                                            ? calculateCashFlow(index)
                                            : calculateNetFarmIncome(index)
                                        : incomeAndExpenses.data?.[index]?.[field] || ''
                        }
                        InputProps={{ readOnly: true }}
                    />
                </Grid>
            ))}
        </>
    );

    return (
        <Box mt={4}>
            <Typography variant="h6" gutterBottom>Income Statement Trend Analysis</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Typography>Year</Typography>
                </Grid>
                {years.map(year => (
                    <Grid item xs={1.5} key={year}>
                        <Typography>{year}</Typography>
                    </Grid>
                ))}
                {renderRow('Total Farm Income', 'totalFarmIncome')}
                {renderRow('Total Farm Expenses', 'totalFarmExpenses')}
                {renderRow('Net Farm Income', 'netFarmIncome', true)}
                {renderRow('Net Non-Farm Income', 'netNonFarmIncome')}
                {renderRow('Depreciation', 'depreciation')}
                {renderRow('Total Interest', 'totalInterest')}
                {renderRow('Ann. Cap. Lease', 'capitalLease')}
                {renderRow('Other Plus Adjustment', 'otherPlusAdjustment')}
                {renderRow('Income & FICA Tax', 'incomeTax')}
                {renderRow('Family Living', 'familyLiving')}
                {renderRow('Non-Recurring', 'nonRecurring')}
                {renderRow('Other Minus Adjustment', 'otherMinusAdjustment')}
                {renderRow('Cash Flow (CFA)', 'cashFlow', true)}
                {renderRow('Annual P&I', 'annualPI')}
                {renderRow('Ann. Cap. Lease', 'annualCapLease')}
                {renderRow('Less P&I new land purchase', 'lessPINewLandPurchase')}
                {renderRow('CFR', 'cfr', true)}
                {renderRow('Excess cash', 'excessCash', true)}
                {renderRow('TDC', 'tdc', true)}
                {renderRow('TDC Shock 3%:', 'tdcShock3')}
                {renderRow('TDC Shock 5%:', 'tdcShock5')}
            </Grid>
        </Box>
    );
});

export default IncomeStatementTrendAnalysis;