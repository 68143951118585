import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import loanDetailStore from '../store';

const ConsolidatedBSAProformaSchedules = observer(() => {
    const { loanData } = loanDetailStore;

    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
    };

    const ScheduleA = () => {
        const scheduleA = loanData?.consolidatedBalanceSheetProforma?.scheduleA || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule A - Real Estate and Debt</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>FMAC Collateral</TableCell>
                            <TableCell>Year</TableCell>
                            <TableCell>Acres</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Purchase Price</TableCell>
                            <TableCell>Market Value</TableCell>
                            <TableCell>Payable To</TableCell>
                            <TableCell>pfe</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Accrued Int</TableCell>
                            <TableCell>Annual Total Prin</TableCell>
                            <TableCell>Annual Total Int</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleA.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.fmacCollateral ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{item.year}</TableCell>
                                <TableCell>{item.acres}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{formatCurrency(item.purchasePrice)}</TableCell>
                                <TableCell>{formatCurrency(item.marketValue)}</TableCell>
                                <TableCell>{item.payableTo}</TableCell>
                                <TableCell>{item.pfe}</TableCell>
                                <TableCell>{formatCurrency(item.balance)}</TableCell>
                                <TableCell>{item.rate}%</TableCell>
                                <TableCell>{formatCurrency(item.accruedInt)}</TableCell>
                                <TableCell>{formatCurrency(item.annualTotalPrin)}</TableCell>
                                <TableCell>{formatCurrency(item.annualTotalInt)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleB = () => {
        const scheduleB = loanData?.consolidatedBalanceSheetProforma?.scheduleB || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule B - Chattel Debt (Machinery, Breeding Livestock, etc.) & Notes Payable</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Holder</TableCell>
                            <TableCell>Security</TableCell>
                            <TableCell>pfe</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Annual Total Int</TableCell>
                            <TableCell>Annual Total Principal</TableCell>
                            <TableCell>Accrued Interest</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleB.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.holder}</TableCell>
                                <TableCell>{item.security}</TableCell>
                                <TableCell>{item.pfe}</TableCell>
                                <TableCell>{formatCurrency(item.balance)}</TableCell>
                                <TableCell>{item.rate}%</TableCell>
                                <TableCell>{formatCurrency(item.annualTotalInt)}</TableCell>
                                <TableCell>{formatCurrency(item.annualTotalPrincipal)}</TableCell>
                                <TableCell>{formatCurrency(item.accruedInterest)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleC = () => {
        const scheduleC = loanData?.consolidatedBalanceSheetProforma?.scheduleC || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule C - Current Debt - Operating Lines, Market Livestock, Due in 12 Months</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Holder</TableCell>
                            <TableCell>Security</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Accrued Int</TableCell>
                            <TableCell>Payable</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleC.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.holder}</TableCell>
                                <TableCell>{item.security}</TableCell>
                                <TableCell>{formatCurrency(item.balance)}</TableCell>
                                <TableCell>{item.rate}%</TableCell>
                                <TableCell>{formatCurrency(item.accruedInt)}</TableCell>
                                <TableCell>{item.payable}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleD = () => {
        const scheduleD = loanData?.consolidatedBalanceSheetProforma?.scheduleD || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule D - Accounts Receivable</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Received From</TableCell>
                            <TableCell>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleD.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.receivedFrom}</TableCell>
                                <TableCell>{formatCurrency(item.amount)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleE = () => {
        const scheduleE = loanData?.consolidatedBalanceSheetProforma?.scheduleE || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule E - Notes Receivable</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Received From</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Rate(%)</TableCell>
                            <TableCell>Principal</TableCell>
                            <TableCell>Interest</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleE.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.receivedFrom}</TableCell>
                                <TableCell>{formatCurrency(item.amount)}</TableCell>
                                <TableCell>{item.rate}</TableCell>
                                <TableCell>{formatCurrency(item.principal)}</TableCell>
                                <TableCell>{formatCurrency(item.interest)}</TableCell>
                                <TableCell>{formatCurrency(item.value)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleF = () => {
        const scheduleF = loanData?.consolidatedBalanceSheetProforma?.scheduleF || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule F - Growing Crops (at Cost)</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Crop</TableCell>
                            <TableCell>Acres</TableCell>
                            <TableCell>Cost Per Acre</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleF.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.crop}</TableCell>
                                <TableCell>{item.acres}</TableCell>
                                <TableCell>{formatCurrency(item.costPerAcre)}</TableCell>
                                <TableCell>{formatCurrency(item.value)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleG = () => {
        const scheduleG = loanData?.consolidatedBalanceSheetProforma?.scheduleG || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule G - Harvested Crops</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Crop</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Units</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleG.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.crop}</TableCell>
                                <TableCell>{item.amount}</TableCell>
                                <TableCell>{item.units}</TableCell>
                                <TableCell>{formatCurrency(item.price)}</TableCell>
                                <TableCell>{formatCurrency(item.value)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleH = () => {
        const scheduleH = loanData?.consolidatedBalanceSheetProforma?.scheduleH || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule H - Breeding Livestock</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>NUM</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleH.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.type}</TableCell>
                                <TableCell>{item.num}</TableCell>
                                <TableCell>{formatCurrency(item.price)}</TableCell>
                                <TableCell>{formatCurrency(item.value)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };


    const ScheduleI = () => {
        const scheduleI = loanData?.consolidatedBalanceSheetProforma?.scheduleI || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule I - Market Livestock</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>NUM</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleI.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.type}</TableCell>
                                <TableCell>{item.num}</TableCell>
                                <TableCell>{formatCurrency(item.price)}</TableCell>
                                <TableCell>{formatCurrency(item.value)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleJ = () => {
        const scheduleJ = loanData?.consolidatedBalanceSheetProforma?.scheduleJ || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule J - Leased Machinery, Equipment and Vehicles</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Leaser</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Purchase Option</TableCell>
                            <TableCell>Number of Pmt</TableCell>
                            <TableCell>Annual Lease Pmt</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleJ.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.leaser}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.purchaseOption}</TableCell>
                                <TableCell>{item.numberOfPmt}</TableCell>
                                <TableCell>{formatCurrency(item.annualLeasePmt)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    const ScheduleK = () => {
        const scheduleK = loanData?.consolidatedBalanceSheetProforma?.scheduleK || [];

        return (
            <Box mb={3}>
                <Typography variant="h6">Schedule K - Owned Machinery, Equipment and Vehicles</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Year</TableCell>
                            <TableCell>Make</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Depr Value</TableCell>
                            <TableCell>Market Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scheduleK.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.year}</TableCell>
                                <TableCell>{item.make}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{formatCurrency(item.deprValue)}</TableCell>
                                <TableCell>{formatCurrency(item.marketValue)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>Consolidated Balance Sheet(a) Schedule Proforma Report</Typography>
            <ScheduleA />
            <ScheduleB />
            <ScheduleC />
            <ScheduleD />
            <ScheduleE />
            <ScheduleF />
            <ScheduleG />
            <ScheduleH />
            <ScheduleI />
            <ScheduleJ />
            <ScheduleK />
        </Box>
    );
});

export default ConsolidatedBSAProformaSchedules;