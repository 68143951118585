// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore'; // Import getFirestore
import authStore from './authStore';

const firebaseConfig = {
  apiKey: "AIzaSyDI_f0Ci9mlif_aEfF2aBzBW6cGzCe3YG4",
  authDomain: "harvestuw-app.firebaseapp.com",
  projectId: "harvestuw-app",
  storageBucket: "harvestuw-app.appspot.com",
  messagingSenderId: "733632458331",
  appId: "1:733632458331:web:06567f09e73b0a9bafd97e",
  measurementId: "G-01HXQ1W7F2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore

enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
    } else if (err.code === 'unimplemented') {
      console.log("The current browser does not support all of the features required to enable persistence");
    }
  });

const handleLogout = async () => {
  try {
    await signOut(auth);
    console.log('Logout successful');
    authStore.setUser(null); // Set the user as logged out
    // Optionally, you can navigate to a different route after successful logout
  } catch (error) {
    console.error('Logout error:', error);
  }
};

const handleLogin = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log('Login successful');
    authStore.setUser(userCredential.user); // Set the user
    // Optionally, you can navigate to a different route after successful login
  } catch (error) {
    console.error('Login error:', error);
  }
};

const handlePasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log('Password reset email sent');
  } catch (error) {
    console.error('Error sending password reset email:', error);
  }
};

export { auth, handleLogin, handlePasswordReset, handleLogout, db };