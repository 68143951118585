import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import loanDetailStore from '../store';

const QuestionnaireSection = observer(() => {
  const { loanData, updateLoanData } = loanDetailStore;

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    updateLoanData({ application: { ...loanData.application, questionnaire: { ...loanData.application.questionnaire, [name]: value === 'Yes' } } });
  };

  return (
    <Box>
      <Typography variant="h6" mb={1}>These questions apply to all applicants.</Typography>
      {[
        'assetsSecurityForDebts',
        'hadBankruptcy',
        'haveJudgments',
        'partyToLawsuit',
        'haveContingentLiabilities',
        'endorsedGuaranteedNotes',
        'obligatedAlimonySupport',
        'propertyForeclosed',
      ].map((question) => (
        <Box key={question} mb={2}>
          <Typography>{question.replace(/([A-Z])/g, ' $1').trim().replace(/^./, (str) => str.toUpperCase())}?</Typography>
          <RadioGroup
            name={question}
            value={loanData?.application?.questionnaire?.[question] ? 'Yes' : 'No'}
            onChange={handleRadioChange}
            row
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </Box>
      ))}
    </Box>
  );
});

export default QuestionnaireSection;