import { makeAutoObservable } from "mobx";

class LoginStore {
  email = "";
  password = "";
  isSignUp = false;
  resetSent = false;
  openResetModal = false;
  multipleTabsOpen = false; // New state for the modal
  loginInProgress = false; // New state variable

  constructor() {
    makeAutoObservable(this);
  }

  setEmail(email) {
    this.email = email;
  }

  setPassword(password) {
    this.password = password;
  }

  setIsSignUp(isSignUp) {
    this.isSignUp = isSignUp;
  }

  setResetSent(resetSent) {
    this.resetSent = resetSent;
  }

  setOpenResetModal(openResetModal) {
    this.openResetModal = openResetModal;
  }

  setMultipleTabsOpen(value) { // New action to update the modal state
    this.multipleTabsOpen = value;
  }  
  setLoginInProgress(value) { // New action to update the state
    this.loginInProgress = value;
  }

}

const store = new LoginStore();
export default store;