import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, FormControlLabel, MenuItem, Grid } from '@mui/material';
import { Radio, RadioGroup, FormControl } from '@mui/material';

import loanDetailStore from '../store';
import { US_STATES } from '../../../constants';

const Security = observer(() => {
    const { loanData, updateLoanData } = loanDetailStore;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        updateLoanData({ application: { ...loanData.application, security: { ...loanData.application.security, [name]: value } } });
    };

    const handleRadioChange = (event) => {
        const { name, value } = event.target;
        updateLoanData({
            application: {
                ...loanData.application,
                security: {
                    ...loanData.application.security,
                    [name]: value === 'yes',
                },
            },
        });
    };

    return (
        <Box>
            <Typography variant="h6" mb={1}>Security</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Acres"
                        name="acres"
                        value={loanData?.application?.security?.acres || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="County"
                        name="county"
                        value={loanData?.application?.security?.county || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        select
                        label="State"
                        name="state"
                        value={loanData?.application?.security?.state || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    >
                        {US_STATES.map((state) => (
                            <MenuItem key={state} value={state}>
                                {state}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="The title is/will be vested in:"
                        name="titleVestedIn"
                        value={loanData?.application?.security?.titleVestedIn || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                        <Typography component="legend">Is this property irrigated?</Typography>
                        <RadioGroup
                            row
                            name="isIrrigated"
                            value={loanData?.application?.security?.isIrrigated ? 'yes' : 'no'}
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" mb={1}>Legal Description</Typography>
                    <TextField
                        name="legalDescription"
                        value={loanData?.application?.security?.legalDescription || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" mb={1}>
                        Additional Security (Include federal &amp; state grazing leases, irrigation equipment, water rights &amp; other equipment necessary to the operation of the security):
                    </Typography>
                    <TextField
                        name="additionalSecurity"
                        value={loanData?.application?.security?.additionalSecurity || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" mb={1}>
                        Description of property and improvements (including leased equipment i.e. irrigation pivots):
                    </Typography>
                    <TextField
                        name="propertyDescription"
                        value={loanData?.application?.security?.propertyDescription || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default Security;
