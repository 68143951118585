import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import loanDetailStore from '../store';

const IndividualBorrowerIncomeStatements = observer(() => {
    const { borrowers } = loanDetailStore;

    const splitCamelCaseToCapitalCase = (field) => {
        return field
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capital letters
            .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
    };

    const renderIncomeStatement = (borrower) => {
        const incomeAndExpenses = borrower.incomeAndExpenses || {};
        const years = incomeAndExpenses.years || 3;
        const mostRecentYear = parseInt(incomeAndExpenses.mostRecentTaxYear) || new Date().getFullYear();

        const formatCurrency = (value) => {
            return value ? `$${parseFloat(value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : 'N/A';
        };

        const calculateNetFarmIncome = (data) => {
            const totalFarmIncome =
                (parseFloat(data.netPurchasedLS) || 0) +
                (parseFloat(data.raisedFarmProducts) || 0) +
                (parseFloat(data.cooperativeDistributions) || 0) +
                (parseFloat(data.agProgramPayments) || 0) +
                (parseFloat(data.cccLoans) || 0) +
                (parseFloat(data.cropInsurance) || 0) +
                (parseFloat(data.customHire) || 0) +
                (parseFloat(data.farmOtherIncomeA) || 0) +
                (parseFloat(data.farmOtherIncomeB) || 0);

            const totalFarmExpenses =
                (parseFloat(data.carAndTruck) || 0) +
                (parseFloat(data.chemicals) || 0) +
                (parseFloat(data.conservation) || 0) +
                (parseFloat(data.customHire) || 0) +
                (parseFloat(data.depreciation) || 0) +
                (parseFloat(data.feed) || 0) +
                (parseFloat(data.fertilizer) || 0) +
                (parseFloat(data.freight) || 0) +
                (parseFloat(data.gasolineFuel) || 0) +
                (parseFloat(data.insurance) || 0) +
                (parseFloat(data.interestMortgageNonCurrent) || 0) +
                (parseFloat(data.otherInterestCurrentLabor) || 0) +
                (parseFloat(data.rentLeaseMachinery) || 0) +
                (parseFloat(data.rentLeaseRealProperty) || 0) +
                (parseFloat(data.repairs) || 0) +
                (parseFloat(data.seed) || 0) +
                (parseFloat(data.savings) || 0) +
                (parseFloat(data.supplies) || 0) +
                (parseFloat(data.taxes) || 0) +
                (parseFloat(data.utilities) || 0) +
                (parseFloat(data.veterinaryFees) || 0) +
                (parseFloat(data.otherExpensesA) || 0) +
                (parseFloat(data.otherExpensesB) || 0);

            return totalFarmIncome - totalFarmExpenses;
        };

        const calculateTotalNonFarmIncome = (data) => {
            return (
                (parseFloat(data.salaries) || 0) +
                (parseFloat(data.interestIncome) || 0) +
                (parseFloat(data.dividends) || 0) +
                (parseFloat(data.businessIncomeSchC) || 0) +
                (parseFloat(data.capitalGainLossSchD) || 0) +
                (parseFloat(data.otherGainLoss4797) || 0) +
                (parseFloat(data.rentalIncomeSchE) || 0) +
                (parseFloat(data.otherIncomeA) || 0) +
                (parseFloat(data.otherIncomeB) || 0) +
                (parseFloat(data.otherIncomeC) || 0)
            );
        };

        const calculateCashFlowAvailable = (data) => {
            const netFarmIncome = calculateNetFarmIncome(data);
            const totalNonFarmIncome = calculateTotalNonFarmIncome(data);
            const plusAdjustments =
                (parseFloat(data.farmDepreciation) || 0) +
                (parseFloat(data.nonFarmDepreciation) || 0) +
                (parseFloat(data.farmCurrentNonCurrentIntF) || 0) +
                (parseFloat(data.nonFarmCurrentNonCurrentIntEE) || 0) +
                (parseFloat(data.capitalLeasePayments) || 0) +
                (parseFloat(data.otherPlusAdjustment) || 0);
            const minusAdjustments =
                (parseFloat(data.incomeAndFICATaxes) || 0) +
                (parseFloat(data.familyLiving) || 0) +
                (parseFloat(data.nonRecurring) || 0) +
                (parseFloat(data.otherMinusAdjustments) || 0);

            return netFarmIncome + totalNonFarmIncome + plusAdjustments - minusAdjustments;
        };

        return (
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>
                    {borrower.type === 'Entity' ? borrower.entityName : `${borrower.firstName} ${borrower.lastName}`}
                    {borrower.is_principal ? ' (Principal Borrower)' : ''}
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            {Array.from({length: years}, (_, i) => (
                                <TableCell key={i} align="right">{mostRecentYear - years + i + 1}</TableCell>
                            ))}
                            <TableCell align="right">Average</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Farm Income */}
                        <TableRow>
                            <TableCell colSpan={years + 2}><Typography variant="subtitle2">Farm Income</Typography></TableCell>
                        </TableRow>
                        {['netPurchasedLS', 'raisedFarmProducts', 'cooperativeDistributions', 'agProgramPayments', 'cccLoans', 'cropInsurance', 'customHire', 'farmOtherIncomeA', 'farmOtherIncomeB'].map((field) => (
                            <TableRow key={field}>
                                <TableCell>{splitCamelCaseToCapitalCase(field)}</TableCell>
                                {Array.from({length: years}, (_, i) => (
                                    <TableCell key={i} align="right">
                                        {formatCurrency(incomeAndExpenses.data?.[years - i - 1]?.[field])}
                                    </TableCell>
                                ))}
                                <TableCell align="right">
                                    {formatCurrency(incomeAndExpenses.data?.reduce((sum, year) => sum + (parseFloat(year[field]) || 0), 0) / years)}
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Farm Expenses */}
                        <TableRow>
                            <TableCell colSpan={years + 2}><Typography variant="subtitle2">Farm Expenses</Typography></TableCell>
                        </TableRow>
                        {['carAndTruck', 'chemicals', 'conservation', 'customHire', 'depreciation', 'feed', 'fertilizer', 'freight', 'gasolineFuel', 'insurance', 'interestMortgageNonCurrent', 'otherInterestCurrentLabor', 'rentLeaseMachinery', 'rentLeaseRealProperty', 'repairs', 'seed', 'savings', 'supplies', 'taxes', 'utilities', 'veterinaryFees', 'otherExpensesA', 'otherExpensesB'].map((field) => (
                            <TableRow key={field}>
                                <TableCell>{splitCamelCaseToCapitalCase(field)}</TableCell>
                                {Array.from({length: years}, (_, i) => (
                                    <TableCell key={i} align="right">
                                        {formatCurrency(incomeAndExpenses.data?.[years - i - 1]?.[field])}
                                    </TableCell>
                                ))}
                                <TableCell align="right">
                                    {formatCurrency(incomeAndExpenses.data?.reduce((sum, year) => sum + (parseFloat(year[field]) || 0), 0) / years)}
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Net Farm Income */}
                        <TableRow>
                            <TableCell><strong>Net Farm Income</strong></TableCell>
                            {Array.from({length: years}, (_, i) => (
                                <TableCell key={i} align="right">
                                    <strong>{formatCurrency(calculateNetFarmIncome(incomeAndExpenses.data?.[years - i - 1] || {}))}</strong>
                                </TableCell>
                            ))}
                            <TableCell align="right">
                                <strong>{formatCurrency(incomeAndExpenses.data?.reduce((sum, year) => sum + calculateNetFarmIncome(year), 0) / years)}</strong>
                            </TableCell>
                        </TableRow>

                        {/* Non-Farm Income */}
                        <TableRow>
                            <TableCell colSpan={years + 2}><Typography variant="subtitle2">Non-Farm Income</Typography></TableCell>
                        </TableRow>
                        {['salaries', 'interestIncome', 'dividends', 'businessIncomeSchC', 'capitalGainLossSchD', 'otherGainLoss4797', 'rentalIncomeSchE', 'otherIncomeA', 'otherIncomeB', 'otherIncomeC'].map((field) => (
                            <TableRow key={field}>
                                <TableCell>{splitCamelCaseToCapitalCase(field)}</TableCell>
                                {Array.from({length: years}, (_, i) => (
                                    <TableCell key={i} align="right">
                                        {formatCurrency(incomeAndExpenses.data?.[years - i - 1]?.[field])}
                                    </TableCell>
                                ))}
                                <TableCell align="right">
                                    {formatCurrency(incomeAndExpenses.data?.reduce((sum, year) => sum + (parseFloat(year[field]) || 0), 0) / years)}
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Total Non-Farm Income */}
                        <TableRow>
                            <TableCell><strong>Total Non-Farm Income</strong></TableCell>
                            {Array.from({length: years}, (_, i) => (
                                <TableCell key={i} align="right">
                                    <strong>{formatCurrency(calculateTotalNonFarmIncome(incomeAndExpenses.data?.[years - i - 1] || {}))}</strong>
                                </TableCell>
                            ))}
                            <TableCell align="right">
                                <strong>{formatCurrency(incomeAndExpenses.data?.reduce((sum, year) => sum + calculateTotalNonFarmIncome(year), 0) / years)}</strong>
                            </TableCell>
                        </TableRow>

                        {/* Cash Flow Coverage */}
                        <TableRow>
                            <TableCell colSpan={years + 2}><Typography variant="subtitle2">Cash Flow Coverage</Typography></TableCell>
                        </TableRow>
                        {['farmDepreciation', 'nonFarmDepreciation', 'farmCurrentNonCurrentIntF', 'nonFarmCurrentNonCurrentIntEE', 'capitalLeasePayments', 'otherPlusAdjustment', 'incomeAndFICATaxes', 'familyLiving', 'nonRecurring', 'otherMinusAdjustments'].map((field) => (
                            <TableRow key={field}>
                                <TableCell>{splitCamelCaseToCapitalCase(field)}</TableCell>
                                {Array.from({length: years}, (_, i) => (
                                    <TableCell key={i} align="right">
                                        {formatCurrency(incomeAndExpenses.data?.[years - i - 1]?.[field])}
                                    </TableCell>
                                ))}
                                <TableCell align="right">
                                    {formatCurrency(incomeAndExpenses.data?.reduce((sum, year) => sum + (parseFloat(year[field]) || 0), 0) / years)}
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Cash Flow Available */}
                        <TableRow>
                            <TableCell><strong>Cash Flow Available</strong></TableCell>
                            {Array.from({length: years}, (_, i) => (
                                <TableCell key={i} align="right">
                                    <strong>{formatCurrency(calculateCashFlowAvailable(incomeAndExpenses.data?.[years - i - 1] || {}))}</strong>
                                </TableCell>
                            ))}
                            <TableCell align="right">
                                <strong>{formatCurrency(incomeAndExpenses.data?.reduce((sum, year) => sum + calculateCashFlowAvailable(year), 0) / years)}</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        );
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>Individual Borrower Income Statements</Typography>
            {borrowers.slice()
                .sort((a, b) => b.is_principal - a.is_principal)
                .map((borrower) => renderIncomeStatement(borrower))}
        </Box>
    );
});

export default IndividualBorrowerIncomeStatements;