import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import authStore from './authStore';
import { auth } from './firebase';

const AuthWrapper = observer(({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      authStore.setUser(user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return <CircularProgress />;
  }

  return children;
});

export default AuthWrapper;