import React from 'react';
import { observer } from 'mobx-react-lite';
import { Paper, Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import loanDetailStore from '../store'; // Adjust the import path as needed

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    margin: theme.spacing(2),
}));

const LoanDataDisplay = observer(() => {
    const { loanData } = loanDetailStore;

    // Helper function to safely get nested values
    const getValue = (path, defaultValue = 0) => {
        return path.split('.').reduce((obj, key) =>
            (obj && obj[key] !== undefined) ? obj[key] : defaultValue, loanData?.loanData || {}
        );
    };

    // Format number as currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    };

    // Format number as percentage
    const formatPercentage = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
        }).format(value / 100);
    };

    const DataItem = ({ label, value }) => (
        <Box mb={1}>
            <Typography variant="body2" component="span">{label}: </Typography>
            <Typography variant="body1" component="span" fontWeight="bold">{value}</Typography>
        </Box>
    );

    return (
        <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>Loan Data:</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <DataItem label="Loan Amount" value={formatCurrency(getValue('loanAmount'))} />
                    <DataItem label="Annual Interest Rate" value={formatPercentage(getValue('interestRate'))} />
                    <DataItem label="Amortization" value={`${getValue('amortization')} years`} />
                    <DataItem label="Payments Per Year" value={getValue('paymentsPerYear')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DataItem label="Term" value={`${getValue('term')} years`} />
                    <DataItem label="First Year's Payment" value={formatCurrency(getValue('firstYearsPayment'))} />
                    <DataItem label="Annualized Interest" value={formatCurrency(getValue('annualizedInterest'))} />
                    <DataItem label="Annual Principal" value={formatCurrency(getValue('annualPrincipal'))} />
                </Grid>
            </Grid>
        </StyledPaper>
    );
});

export default LoanDataDisplay;