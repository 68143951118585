import React from 'react';
import { observer } from 'mobx-react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { handleLogin, handlePasswordReset, auth } from '../../firebase';
import store from './store';
import CircularProgress from '@mui/material/CircularProgress';
import { signInWithPopup, OAuthProvider } from "firebase/auth";


const LoginComponent = observer(() => {
    // LoginComponent
    const handleLoginClick = async () => {
        store.setLoginInProgress(true); // Set loginInProgress to true when the login request starts
        try {
            await handleLogin(store.email, store.password);
            // Optionally, you can navigate to a different route after successful login
        } catch (error) {
            console.error('Login error:', error);
        } finally {
            store.setLoginInProgress(false); // Set loginInProgress to false when the login request ends
        }
    };

    const handleMicrosoftLoginClick = async () => {
        const provider = new OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            // Target specific email with login hint.
            login_hint: 'user@cultivatedlending.com',
            tenant: '76dfa12f-007a-460f-9b8a-da36e8ef1974'
        });
        try {
            await signInWithPopup(auth, provider);
            // Optionally, you can navigate to a different route after successful login
        } catch (error) {
            console.error('Microsoft login error:', error);
        }
    };

    const handlePasswordResetClick = async () => {
        try {
            await handlePasswordReset(store.email);
            store.setResetSent(true);
            console.log('Password reset email sent');
        } catch (error) {
            console.error('Error sending password reset email:', error);
        }
    };

    return (
        <div style={{
            // backgroundImage: `url(/img/img-f4nk0lnTLDFKy2tzskxYqpau.png)`, // replace with the path to your image relative to the public directory
            // backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Container maxWidth="sm">
                <Box
                    mt={4}
                    p={4}
                    textAlign="center"
                    bgcolor="white"
                    boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                    borderRadius="4px"
                >
                    <Typography variant="h5" component="h1" gutterBottom>
                        harvest.uw
                    </Typography>
                    <Box mt={2}>
                        <Button
                            variant={store.isSignUp ? "outlined" : "contained"}
                            color="primary"
                            sx={{ mr: 1 }}
                            onClick={() => store.setIsSignUp(false)}
                        >
                            Sign In
                        </Button>
                        <Button
                            variant={store.isSignUp ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => store.setIsSignUp(true)}
                        >
                            Sign Up
                        </Button>
                    </Box>
                    {store.isSignUp ? (
                        <Box mt={4}>
                            <Typography variant="h6" gutterBottom>
                                Invite Request
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Fill out the information below to send an invite request to your
                                organization administrator.
                            </Typography>
                            <Box mt={2}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={store.email}
                                    onChange={(e) => store.setEmail(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mb: 2 }}
                                >
                                    Request Invite (Does nothing)
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Box mt={4}>
                            <Typography variant="h6" gutterBottom>
                                Welcome Back
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Fill out the information below in order to access your account.
                            </Typography>
                            <Box mt={2}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={store.email}
                                    onChange={(e) => store.setEmail(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    type="password"
                                    value={store.password}
                                    onChange={(e) => store.setPassword(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleLoginClick}
                                    disabled={store.loginInProgress} // Disable the button if a login request is in progress
                                    sx={{ mb: 2 }}
                                >
                                    {store.loginInProgress ? <CircularProgress size={24} /> : 'Log In'}

                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleMicrosoftLoginClick}
                                    sx={{ mb: 2 }}
                                >
                                    Sign in with SSO
                                </Button>
                                <Link
                                    href="#"
                                    variant="body2"
                                    color="textSecondary"
                                    onClick={() => store.setOpenResetModal(true)}
                                >
                                    Forgot Password?
                                </Link>
                            </Box>
                        </Box>

                    )}
                </Box>
                <Dialog open={store.openResetModal} onClose={() => store.setOpenResetModal(false)}>
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogContent>
                        {store.resetSent ? (
                            <Typography variant="body1">
                                A password reset email has been sent to your email address.
                            </Typography>
                        ) : (
                            <>
                                <Typography variant="body1">
                                    Enter your email address to receive a password reset link.
                                </Typography>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={store.email}
                                    onChange={(e) => store.setEmail(e.target.value)}
                                    sx={{ mt: 2 }}
                                />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => store.setOpenResetModal(false)}>Cancel</Button>
                        <Button
                            onClick={handlePasswordResetClick}
                            disabled={store.resetSent}
                            variant="contained"
                            color="primary"
                        >
                            {store.resetSent ? 'Email Sent' : 'Reset Password'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    );
});

export default LoginComponent;