// LoanOverview.js
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, MenuItem, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import loanDetailStore from '../store';

const LoanOverview = observer(() => {
  const { loanData, updateLoanData, fetchUserName, userName } = loanDetailStore;

  useEffect(() => {
    if (loanData?.created_by) {
      fetchUserName(loanData.created_by);
    }
  }, [fetchUserName, loanData]);

  const handleDateChange = (date) => {
    updateLoanData({ applicationDate: date });
  };

  const handleYearChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,4}$/.test(value)) {
      updateLoanData({ mostRecentYear: value });
    }
  };

  const handleStatusChange = (event) => {
    updateLoanData({ status: event.target.value });
  };

  return (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom mb={1}>
        Loan Overview
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Application Date"
              value={loanData?.applicationDate?.toDate ? loanData?.applicationDate?.toDate() : null}
              onChange={handleDateChange}
              format="MM/dd/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Most Recent Inc/Exp Year"
            value={loanData?.mostRecentYear || ''}
            onChange={handleYearChange}
            inputProps={{ maxLength: 4 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Status"
            value={loanData?.status || 'Pending'}
            onChange={handleStatusChange}
            select
            fullWidth
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Submitted">Submitted</MenuItem>
            <MenuItem value="Underwriting Processing">Underwriting Processing</MenuItem>
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Closed and Funded">Closed and Funded</MenuItem>
            <MenuItem value="Denied">Denied</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Current Underwriter"
            value={userName || ''}
            InputProps={{ readOnly: true }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default LoanOverview;