import { makeAutoObservable } from 'mobx';

class AuthStore {
  user = null;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(user) {
    this.user = user;
  }

  get isLoggedIn() {
    return !!this.user;
  }
}

const authStore = new AuthStore();
export default authStore;