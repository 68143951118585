// BalanceSheetProforma.js
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Grid } from '@mui/material';
import loanDetailStore from '../store';
import CurrentAssets from './CurrentAssets';
import CurrentLiabilities from './CurrentLiabilities';
import IntermediateAssets from './IntermediateAssets';
import IntermediateLiabilities from './IntermediateLiabilities';
import LongTermAssets from './LongTermAssets';
import LongTermLiabilities from './LongTermLiabilities';
import TotalAssets from './TotalAssets';
import TotalLiabilities from './TotalLiabilities';

const BalanceSheetProforma = observer(() => {
    const { borrowers } = loanDetailStore;

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" mb={2}>
            <Typography variant="h6" mb={1}>
                Balance Sheet Proforma
            </Typography>
            <Grid container spacing={4}>
                <CurrentAssets borrowers={borrowers} proforma />
                <CurrentLiabilities borrowers={borrowers} proforma />
                <IntermediateAssets borrowers={borrowers} proforma />
                <IntermediateLiabilities borrowers={borrowers} proforma />
                <LongTermAssets borrowers={borrowers} proforma />
                <LongTermLiabilities borrowers={borrowers} proforma />
                <TotalAssets borrowers={borrowers} proforma />
                <TotalLiabilities borrowers={borrowers} proforma />
            </Grid>
        </Box>
    );
});

export default BalanceSheetProforma;