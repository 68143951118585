import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, Tabs, Tab, Button } from '@mui/material';
import SellerBorrowerLoanSummary from './SellerBorrowerLoanSummary';
import PeerDataBalanceSheetTrendAnalysis from './PeerDataBalanceSheetTrendAnalysis';
import LoanSummaryReport from './LoanSummaryReport';

const LoanSummary = observer(() => {
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [showReport, setShowReport] = useState(false);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>Loan Summary</Typography>
            <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Seller, Borrower and Loan Summary Information" />
                <Tab label="Peer Data, Balance Sheet, and Income Statement Trend Analysis" />
            </Tabs>
            {selectedTab === 0 && <SellerBorrowerLoanSummary />}
            {selectedTab === 1 && <PeerDataBalanceSheetTrendAnalysis />}

            <Button
                variant="contained"
                onClick={() => setShowReport(!showReport)}
                sx={{ mt: 2 }}
            >
                {showReport ? 'Hide Report' : 'Generate Report'}
            </Button>

            {showReport && <LoanSummaryReport />}
        </Box>
    );
});

export default LoanSummary;