import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField } from '@mui/material';
import loanDetailStore from '../store';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

const OriginatorInformation = observer(() => {
    const { loanData } = loanDetailStore;
    const [originatorData, setOriginatorData] = useState(null);

    useEffect(() => {
        const fetchOriginatorData = async () => {
            if (loanData?.lending_organization) {
                const organizationRef = doc(db, 'organizations', loanData.lending_organization.id);
                const organizationDoc = await getDoc(organizationRef);
                if (organizationDoc.exists()) {
                    setOriginatorData(organizationDoc.data());
                }
            }
        };

        fetchOriginatorData();
    }, [loanData?.lending_organization]);

    return (
        <Box>
            <Typography variant="h6" mb={1}>Originator Information</Typography>
            <TextField
                label="Originator Name"
                value={originatorData?.name || ''}
                fullWidth
                margin="normal"
                InputProps={{
                    readOnly: true,
                }}
            />
            <TextField
                label="Contact Name"
                value={originatorData?.display_name || ''}
                fullWidth
                margin="normal"
                InputProps={{
                    readOnly: true,
                }}
            />
            <Box display="flex">
                <TextField
                    label="Street Address"
                    value={originatorData?.street_address || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="City"
                    value={originatorData?.city || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="State"
                    value={originatorData?.state || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
            <Box display="flex">
                <TextField
                    label="Phone Number"
                    value={originatorData?.phone_number || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Fax"
                    value={originatorData?.fax_number || ''}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
        </Box>
    );
});

export default OriginatorInformation;